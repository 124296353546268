import { Alert, Button, Space } from 'antd';
import { Formik } from 'formik';
import { Form, Input, Radio, Slider, SubmitButton } from 'formik-antd';
import { ChangeEvent, useEffect, useState } from 'react';
import * as Yup from 'yup';

import SosIcon from '@/assets/icons/sos.png';
import { Card } from '@/components/atoms';
import { PageHeader } from '@/components/organisms';
import { LayoutNarrow } from '@/components/templates';
import { LocationInput } from '@/forms/components';
import { location as locationValidationSchema } from '@/forms/validationSchema';
import { SosWhy, useSosNewContainerSosCostQuery } from '@/graphql';

import { Map } from './components';
import { SosNewContainerValues } from './SosNewContainer';
import { toLatLng } from './toLatLng';

type Props = {
    onChange: (values: SosNewContainerValues) => void;
    onSubmit: () => void;
    values: SosNewContainerValues;
    cost: number;
};

const SosNewView = ({ onChange, onSubmit, values, cost }: Props) => {
    const validationSchema = Yup.object().shape({
        location: locationValidationSchema,
        message: Yup.string().max(150, 'Message must be 150 characters or less').required('Please enter a description'),
        why: Yup.string().required('Please choose an option'),
    });
    const handleDistanceChange = (value: number) => onChange({ ...values, distance: value });
    const handleLocationChange = async (event: ChangeEvent<HTMLInputElement>) => {
        let location = { lat: 54.7781, lng: -3.436 };
        const input = event.target.value;

        if (input) location = await toLatLng(input);
        Object.assign(location, { location: input });

        onChange({ ...values, ...location });
    };

    return (
        <>
            <PageHeader
                icon={{ src: SosIcon }}
                subTitle={
                    <a href="https://www.facebook.com/christine.singleton.184/videos/10204379637326858/UzpfSTExMjY4NjI0NTg1Mjg1Njo0MjkyODcxODA4NTk0MjY" rel="noopener noreferrer" target="_blank">
                        <Button type="link">What is an SOS?</Button>
                    </a>
                }
                title="Make SOS Request"
            />
            <LayoutNarrow>
                <Card>
                    <h1 style={{ textAlign: 'center' }}>The UK's only messaging support system that immediately responds to urgent requests for missing or broken care-based essentials to help individuals</h1>
                    <Alert
                        description={
                            <>
                                <p>A suitable SOS is one where item(s)requested are required immediately (at home, on holiday, or on a day out), as without it/them someone's daily routine will be detrimentally affected:</p>
                                <p>
                                    <strong>My daughters feeding tube has cracked. I am on a day out with her. To fit Enfit syringes.</strong>
                                </p>
                                <>All SOS's will be checked and verified by our moderators. Please check your emails and/or text messages in case our moderators contact you.</>
                            </>
                        }
                        message="What is an SOS?"
                        showIcon
                        type="info"
                    />
                    <br />
                    <Alert
                        description={
                            <ul>
                                <li> Prescription medications, sharps, needles or oxygen cylinders (full or empty)</li>
                                <li> NHS equipment (all NHS equipment should be returned to the local NHS loan store)</li>
                            </ul>
                        }
                        message="There should be NO MOVEMENT of:"
                        showIcon
                        type="error"
                    />
                    <Formik initialValues={values} onSubmit={onSubmit} validationSchema={validationSchema}>
                        <Form colon={false} layout="vertical" size="large">
                            <Card title="Where are you?">
                                <Form.Item label="Location" name="location">
                                    {/* @ts-ignore */}
                                    <LocationInput name="location" onChange={handleLocationChange} />
                                </Form.Item>

                                <Form.Item
                                    label={
                                        <p>
                                            How far are you willing to travel <strong> in miles</strong>?
                                        </p>
                                    }
                                    name="distance"
                                >
                                    <Slider
                                        marks={{
                                            1: '1',
                                            2: '2',
                                            5: '5',
                                            10: '10',
                                            15: '15',
                                            20: '20',
                                        }}
                                        max={20}
                                        name="distance"
                                        onAfterChange={handleDistanceChange}
                                        step={null}
                                    />
                                </Form.Item>

                                <Map center={{ lat: values.lat, lng: values.lng }} containerElement={<div style={{ height: `400px` }} />} distance={values.distance} mapElement={<div style={{ height: `100%` }} />} />
                            </Card>

                            <Card title="Details">
                                <Form.Item label="Give a brief description of your request, including the quantity you require" name="message">
                                    <Input.TextArea maxLength={150} name="message" onChange={(e) => onChange({ ...values, message: e.target.value })} />
                                    <div style={{ textAlign: 'right', marginTop: '5px' }}>{values.message.length}/150 characters</div>
                                </Form.Item>

                                <Form.Item label="Where are you/why do you need to send an SOS?" name="why">
                                    <Alert message="This information will help us identify key areas of need. The data will be used, anomalously, to support Skiggle with its growth and funding applications." showIcon style={{ marginBottom: 15 }} type="info" />
                                    <Radio.Group name="why">
                                        <Space direction="vertical">
                                            <Radio name="why" value={SosWhy.AtHome}>
                                                At home
                                            </Radio>
                                            <Radio name="why" value={SosWhy.OnHoliday}>
                                                On holiday
                                            </Radio>
                                            <Radio name="why" value={SosWhy.InHospital}>
                                                In hospital
                                            </Radio>
                                            <Radio name="why" value={SosWhy.OnADayOut}>
                                                On a day out
                                            </Radio>
                                            <Radio name="why" value={SosWhy.LateStockDelivery}>
                                                Late stock delivery
                                            </Radio>
                                            <Radio name="why" value={SosWhy.Other}>
                                                Other
                                            </Radio>
                                        </Space>
                                    </Radio.Group>
                                </Form.Item>
                            </Card>

                            <Form.Item name="everythingCorrect">
                                <Alert description="Please ensure all details are correct before posting your request to ensure the best possible response" message="Everything correct?" showIcon type="info" />
                            </Form.Item>

                            <Form.Item name="costMessage">
                                <input name="cost" type="hidden" value={cost} />
                                <Alert
                                    description={`To ensure the sustainability of our SMS SOS service, we've introduced a required donation of £${cost.toFixed(2)}. This donation is calculated based on the number of SMS recipients. Adjust the map distance to modify both the donation and recipient count. Requests will be on hold until the donation is completed`}
                                    message="Compulsory Donation"
                                    showIcon
                                    type="warning"
                                />
                            </Form.Item>

                            <Form.Item name="controls">
                                <SubmitButton block>Make Request</SubmitButton>
                            </Form.Item>
                        </Form>
                    </Formik>
                </Card>
            </LayoutNarrow>
        </>
    );
};

export { SosNewView };
