import { gql } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import { useSosMySosContainerDeleteSosMutation, useSosMySosContainerPaidSosMutation, useSosMySosContainerQuery } from '@/graphql';
import { viewFactory } from '@/utils';

import { SosMySosView } from './SosMySosView';

const SosMySosContainer = () => {
    const history = useHistory();
    const query = useSosMySosContainerQuery();
    const [deleteSos] = useSosMySosContainerDeleteSosMutation({
        update(cache, { data: { deleteSos } }) {
            cache.modify({
                fields: {
                    me() {
                        const query = gql`
                            query me {
                                me {
                                    id
                                    mySos {
                                        id
                                    }
                                }
                            }
                        `;

                        const data = cache.readQuery({ query });

                        const updatedMySos = data.me.mySos.filter((sos) => sos.id !== deleteSos.id).map(({ id }) => ({ __ref: `Sos:${id}` }));

                        cache.writeFragment({
                            id: `User:${data.me.id}`,
                            fragment: gql`
                                fragment meUser on User {
                                    mySos
                                }
                            `,
                            data: {
                                mySos: updatedMySos,
                                __typename: 'User',
                            },
                        });
                    },
                },
            });
        },
    });

    const [paidSos] = useSosMySosContainerPaidSosMutation({
        onCompleted: (data) => {
            query.refetch();
        },
    });

    return viewFactory(SosMySosView, query, {
        onBack: () => history.push('/sos/my-sos'),
        onDelete: (id) =>
            deleteSos({
                variables: { id },
            }),
        onPaid: ({ id, session, price, session_id }) =>
            paidSos({
                variables: { id, session, price, session_id },
            }),
    });
};

export { SosMySosContainer };
