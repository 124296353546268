import { CheckCircleFilled } from '@ant-design/icons';
import { message, Modal } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import Compressor from 'compressorjs';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { donatePopupFields, GetVerificationPopupMsg, VerificationPopupFields } from '@/components/atoms';
import { MarketplaceCategory, MarketplaceItemCondition, MarketplaceItemDelivery, useMarketplaceNewContainerCheckoutMarketplaceItemMutation, useMarketplaceNewContainerCreateMarketplaceItemMutation } from '@/graphql';
import { useMeQuery } from '@/hooks';

import { MarketplaceNewView } from './MarketplaceNewView';

type Values = {
    title: string;
    category?: MarketplaceCategory;
    categoryId: string;
    description: string;
    expiresOn?: string;
    condition: MarketplaceItemCondition;
    bereavement: boolean;
    images: UploadFile[] | null;
    location: string;
    delivery: MarketplaceItemDelivery;
    isFree: boolean;
    price: number;
};

const defaultValues: Values = {
    title: '',
    category: undefined,
    categoryId: '',
    description: '',
    condition: MarketplaceItemCondition.New,
    bereavement: false,
    images: null,
    location: '',
    delivery: MarketplaceItemDelivery.Included,
    isFree: true,
    price: 0.01,
};

const compressImage = async (file: any) => {
    return new Promise((resolve, reject) => {
        new Compressor(file.originFileObj, {
            maxHeight: 600,
            maxWidth: 600,
            success(normalizedFile) {
                resolve(normalizedFile);
            },
            error(error) {
                reject(error);
            },
        });
    });
};

const MarketplaceNewContainer = () => {
    const [step, setStep] = useState(0);
    const [values, setValues] = useState<Values>(defaultValues);
    const history = useHistory();
    const { data, error, loading } = useMeQuery();
    useEffect(() => {
        if (data && data.me && (!data.me.emailVerified || !data.me.mobileVerified)) {
            Modal.warning({
                ...VerificationPopupFields,
                content: GetVerificationPopupMsg(data.me),
                onOk: () => {
                    history.push('/profile');
                },
            });
        }
    }, [data]);

    const [checkoutMarketplaceItem] = useMarketplaceNewContainerCheckoutMarketplaceItemMutation({
        onError: (error) => message.error(error.message),
    });

    const showPopups = () => {
        Modal.confirm({
            cancelText: 'No',
            centered: true,
            content: 'Would you like to create another?',
            icon: <CheckCircleFilled />,
            okText: 'Yes',
            onCancel: () => {
                Modal.confirm({
                    ...donatePopupFields,
                });
                history.push('/marketplace');
            },
            onOk: () => {
                setValues(defaultValues);
                setStep(1);
            },
            title: 'Successfully submitted!',
            type: 'success',
        });
    };

    const [createMarketplaceItem] = useMarketplaceNewContainerCreateMarketplaceItemMutation({
        onCompleted: async (data) => {
            const { isFree, price } = values;
            if (data && data.createMarketplaceItem && !isFree && price > 0) {
                Modal.confirm({
                    cancelText: 'No, I will pay later',
                    okText: 'Yes',
                    title: 'Confirm',
                    icon: <CheckCircleFilled />,
                    content: 'Would you like to proceed to payment?',
                    type: 'success',
                    onOk: async () => {
                        const checkoutResponse = await checkoutMarketplaceItem({
                            variables: {
                                id: data.createMarketplaceItem.id,
                            },
                        });
                        if (checkoutResponse && checkoutResponse.data && checkoutResponse.data.checkoutMarketplaceItem) {
                            history.push('/marketplace');
                            window.open(checkoutResponse.data.checkoutMarketplaceItem, '_self');
                        }
                    },
                    onCancel: () => {
                        showPopups();
                    },
                });
            } else {
                showPopups();
            }
        },
        onError: (error) => message.error(error.message),
    });

    const handleBack = () => history.push('/marketplace');
    const handleSubmit = async () => {
        const { category, images, isFree, price, ...input } = values;
        const priceInPence = price * 100;
        let promisesAll: any = [];
        images?.forEach((image) => {
            promisesAll.push(compressImage(image));
        });
        let result = await Promise.all(promisesAll);

        await createMarketplaceItem({
            variables: {
                input: {
                    ...input,
                    images: result,
                    price: priceInPence,
                },
            },
        });
    };

    if (loading) return <h1>Loading</h1>;
    if (error) return <h1>{error.message}</h1>;

    return <MarketplaceNewView onBack={handleBack} onSubmit={handleSubmit} setStep={setStep} setValues={setValues} step={step} values={values} />;
};

export { MarketplaceNewContainer };
export type { Values as MarketplaceNewContainerValues };
