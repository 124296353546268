const donatePopupFields = {
    title: 'Help us by donating',
    content: (
        <p>
            We rely on outside support so are immensely grateful for every donation we receive. Your support will help us to:
            <ul>
                <li>Ensure vulnerable families and carers can send an SOS in difficult times</li>
                <li>Reduce waste and relieve pressure for supplies on the NHS</li>
                <li>Support a growing community of carers deal with challenging times</li>
            </ul>
        </p>
    ),
    onOk: () => {
        window.open('https://www.paypal.com/donate/?hosted_button_id=36GXB2BW2YP2U', '_self');
    },
    cancelText: 'Not now',
    okText: 'Donate',
};

export { donatePopupFields };
