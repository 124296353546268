import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, List, Modal, message as notify } from 'antd';
import React, { useState } from 'react';
import { useModal } from 'react-modal-hook';

import SosIcon from '@/assets/icons/sos.png';
import { Card, SosDetailsModal, SosItem } from '@/components/atoms';
import { PageHeader } from '@/components/organisms';
import { Layout } from '@/components/templates';
import { Sos, useSosEditContainerEditSosItemMutation } from '@/graphql';

import { RejectModal } from './components';
import TextArea from 'antd/lib/input/TextArea';

type Props = {
    data?: Pick<Sos, 'createdAt' | 'distance' | 'id' | 'message' | 'status' | 'user' | 'isItemPaid' | 'paidPrice' | 'paymentBreakdown'>[];
    loading: boolean;
    onApprove: (id: string) => Promise<void>;
    onReject: (id: string, reason: string, feedback: string) => Promise<void>;
};

const SosPendingView = ({ data, loading, onApprove, onReject }: Props) => {
    const [selectedId, setSelectedId] = useState('');
    const [showRejectModal, hideRejectModal] = useModal(() => <RejectModal id={selectedId} onCancel={hideRejectModal} onOk={handleReject} />, [selectedId]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [mySos, setMySos] = useState<Array<Sos>>([]);
    const [message, setMessage] = useState<string>('');
    const handleReject = (id: string, reason: string, feedback: string) => {
        hideRejectModal();
        onReject(id, reason, feedback);
    };

    const handleRejectClick = (id: string) => {
        setSelectedId(id);
        showRejectModal();
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const [editSos] = useSosEditContainerEditSosItemMutation({
        onError: (error) => notify.error(error.message),
    });

    const [isEditModalVisible, setIsEditModalVisible] = useState(false);

    const showEditModal = (sos: { id: string; message: string }) => {
        setSelectedId(sos.id);
        setMessage(sos.message);
        setIsEditModalVisible(true);
    };

    const handleEditModalOk = async () => {
        await editSos({
            variables: {
                input: {
                    id: selectedId,
                    message,
                },
            },
        });
        setIsEditModalVisible(false);
    };

    const handleEditModalCancel = () => {
        setIsEditModalVisible(false);
    };

    return (
        <>
            <PageHeader icon={{ src: SosIcon }} title="Pending SOS Requests" />
            <Layout>
                <Card>
                    <List
                        dataSource={data}
                        itemLayout="vertical"
                        loading={loading}
                        locale={{
                            emptyText: 'There are no pending SOS requests',
                        }}
                        renderItem={(sos) => {
                            return (
                                <SosItem
                                    {...sos}
                                    actions={[
                                        <Button onClick={() => showEditModal(sos)} type="ghost">
                                            Edit Message
                                        </Button>,
                                        <Button
                                            onClick={() => {
                                                Modal.confirm({
                                                    title: 'Confirm',
                                                    icon: <ExclamationCircleOutlined />,
                                                    content: (
                                                        <>
                                                            Are you sure you want to approve the following the SOS request?
                                                            <div>
                                                                "<i>{sos.message}</i>"
                                                            </div>
                                                        </>
                                                    ),
                                                    okText: 'Proceed',
                                                    cancelButtonProps: {
                                                        danger: true,
                                                    },
                                                    onOk() {
                                                        onApprove(sos.id);
                                                    },
                                                    onCancel() {},
                                                });
                                            }}
                                            type="primary"
                                        >
                                            Approve
                                        </Button>,
                                        <Button danger onClick={() => handleRejectClick(sos.id)}>
                                            Reject
                                        </Button>,
                                        sos && sos.user && sos.user.mySos ? (
                                            <Button
                                                onClick={() => {
                                                    setMySos(sos.user.mySos);
                                                    showModal();
                                                }}
                                                type="link"
                                            >
                                                SOS History ({sos.user.mySos.length})
                                            </Button>
                                        ) : null,
                                    ]}
                                />
                            );
                        }}
                        size="large"
                    />
                </Card>
                <SosDetailsModal handleCancel={handleCancel} isModalVisible={isModalVisible} messages={mySos} page="pending" />
            </Layout>
            <Modal  okText="Update" onCancel={handleEditModalCancel} onOk={handleEditModalOk} title="Edit Message" visible={isEditModalVisible} >
                <TextArea maxLength={150} name="message" onChange={(e) => setMessage(e.target.value)}  value={message}/>
                <div style={{ textAlign: 'right', marginTop: '5px' }}>{message.length}/150 characters</div>
            </Modal>
        </>
    );
};

export { SosPendingView };
