import { message } from 'antd';

import { useSosPendingContainerApproveSosMutation, useSosPendingContainerQuery, useSosPendingContainerRejectSosMutation } from '@/graphql';

import { SosPendingView } from './SosPendingView';

const SosPendingContainer = () => {
    const { data, error, loading } = useSosPendingContainerQuery();
    const [approveSos] = useSosPendingContainerApproveSosMutation();
    const [rejectSos] = useSosPendingContainerRejectSosMutation();

    const handleApprove = async (id: string) => {
        try {
            const { data } = await approveSos({ variables: { id } });
            if (!data?.approveSos) throw new Error();

            message.success('SOS successfully approved');
        } catch {
            message.error('Unable to approve SOS');
        }
    };
    const handleReject = async (id: string, reason: string, feedback: string) => {
        try {
            const { data } = await rejectSos({ variables: { feedback, id, reason } });
            if (!data?.rejectSos) throw new Error();

            message.success('SOS successfully rejected');
        } catch {
            message.error('Unable to reject SOS');
        }
    };

    if (error) return 'Error';

    const items = data ? data.sos.filter((sos) => sos.status === 'PENDING' && sos.isItemPaid) : [];

    return <SosPendingView data={items} loading={loading} onApprove={handleApprove} onReject={handleReject} />;
};

export { SosPendingContainer };
