import { message as notify, Modal } from 'antd';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { GetVerificationPopupMsg, VerificationPopupFields } from '@/components/atoms';
import { withErrorsFeedback } from '@/forms';
import { SosWhy, useSosNewContainerCheckoutSosMutation, useSosNewContainerCreateSosMutation, useSosNewContainerSosCostQuery } from '@/graphql';
import { useMeQuery } from '@/hooks';

import { SosNewView } from './SosNewView';

type Values = {
    distance: number;
    lat: number;
    lng: number;
    location: string;
    message: string;
    why: SosWhy;
};

const defaultValues: Values = {
    distance: 10,
    lat: 54.7781,
    lng: -3.436,
    location: '',
    message: '',
    why: SosWhy.AtHome,
};

const SosNewContainer = () => {
    const history = useHistory();
    const [values, setValues] = useState<Values>(defaultValues);
    const [createSos] = useSosNewContainerCreateSosMutation();
    const { data, error, loading } = useMeQuery();
    const handleChange = (values: Values) => {
        setValues(values);
        refetch({
            distance: values.distance,
            long: values.lng,
            lat: values.lat,
        });
    };
    const [checkoutSos] = useSosNewContainerCheckoutSosMutation({
        onError: (error) => notify.error(error.message),
    });
    useEffect(() => {
        if (data && data.me && (!data.me.emailVerified || !data.me.mobileVerified)) {
            Modal.warning({
                ...VerificationPopupFields,
                content: GetVerificationPopupMsg(data.me),
                onOk: () => {
                    history.push('/profile');
                },
            });
        }
    }, [data]);

    const [cost, setCost] = useState<number>(0);
    const { data: costData, refetch } = useSosNewContainerSosCostQuery({
        variables: {
            distance: values.distance,
            long: values.lng,
            lat: values.lat,
        },
        skip: !values.distance || !values.lng || !values.lat,
    });
    useEffect(() => {
        if (costData?.sosCost || costData?.sosCost === 0) {
            setCost(Number(costData?.sosCost));
        }
    }, [costData?.sosCost]);

    const handleSubmit = withErrorsFeedback(async ({ message, why }: Values) => {
        const { distance, lat: latitude, lng: longitude, location } = values;

        const { data } = await createSos({
            variables: {
                input: {
                    distance,
                    latitude,
                    longitude,
                    location,
                    message,
                    why,
                },
            },
        });
        const hasRecipients = data?.createSos;

        if (hasRecipients) {
            Modal.confirm({
                title: 'Step 2: Payment *',
                cancelText: 'Cancel',
                okText: 'Yes, Pay Now',
                content: (
                    <>
                        <p>
                            <em>Once we have received payment for your SOS SMS, your message will be reviewed by one of our admin before being delivered to members</em>
                        </p>
                        <p style={{ backgroundColor: '#fffbe6', border: '1px solid #ffe58f', padding: '10px', borderRadius: '5px' }}>
                            {`To ensure the sustainability of our SMS SOS service, we've introduced a required donation of £${cost.toFixed(2)}. This donation is calculated based on the number of SMS recipients. Adjust the map distance to modify both the donation and recipient count. Requests will be on hold until the donation is completed.`}
                        </p>
                        <p>Would you like to proceed to payment?</p>
                    </>
                ),
                onOk: async () => {
                    const checkoutResponse = await checkoutSos({
                        variables: {
                            id: String(data?.createSos?.id),
                        },
                    });
                    if (checkoutResponse && checkoutResponse.data && checkoutResponse.data.checkoutSos) {
                        window.open(checkoutResponse.data.checkoutSos, '_self');
                    }
                },
                onCancel: () => {
                    history.push('/sos/my-sos');
                },
            });
        } else {
            Modal.error({
                title: 'No Available Recipients',
                content: 'Sorry, there are no registered users available to receive your request in the location provided. You could try increasing your travelling distance.',
            });
        }
    });
    if (loading) return <h1>Loading</h1>;
    if (error) return <h1>{error.message}</h1>;
    return <SosNewView cost={cost} onChange={handleChange} onSubmit={handleSubmit} values={values} />;
};

export { SosNewContainer };
export type { Values as SosNewContainerValues };
