import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, List, Modal } from 'antd';
import { useState } from 'react';
import { useModal } from 'react-modal-hook';

import MarketplaceIcon from '@/assets/icons/marketplace.png';
import { Card, MarketplaceItem } from '@/components/atoms';
import { PageHeader } from '@/components/organisms';
import { Layout } from '@/components/templates';
import { MarketplacePendingContainerQuery } from '@/graphql';

import { RejectModal } from './components';

type Props = {
    data?: MarketplacePendingContainerQuery;
    loading: boolean;
    onApprove: (id: string) => Promise<void>;
    onReject: (id: string, reason: string, feedback: string) => Promise<void>;
};

const MarketplacePendingView = ({ data, loading, onApprove, onReject }: Props) => {
    const [selectedId, setSelectedId] = useState('');
    const [showRejectModal, hideRejectModal] = useModal(() => <RejectModal id={selectedId} onCancel={hideRejectModal} onOk={handleReject} />, [selectedId]);

    const handleReject = (id: string, reason: string, feedback: string) => {
        hideRejectModal();
        onReject(id, reason, feedback);
    };

    const handleApproveClick = (id: string, name: string) => {
        Modal.confirm({
            title: 'Confirm',
            icon: <ExclamationCircleOutlined />,
            content: (
                <>
                    Are you sure you want to approve the following item?
                    <div>
                        "<i>{name}</i>"
                    </div>
                </>
            ),
            okText: 'Proceed',
            cancelButtonProps: {
                danger: true,
            },
            onOk() {
                onApprove(id);
            },
            onCancel() {},
        });
    };

    const handlePaymentDetails = (paymentUrl: string) => {
        window.open(paymentUrl, '_self');
    };

    const handleRejectClick = (id: string) => {
        setSelectedId(id);
        showRejectModal();
    };

    return (
        <>
            <PageHeader icon={{ src: MarketplaceIcon }} title="Pending Marketplace Items" />
            <Layout>
                <Card>
                    <List
                        bordered={false}
                        dataSource={data?.marketplaceItems}
                        itemLayout="vertical"
                        loading={loading}
                        locale={{
                            emptyText: 'There are no pending Marketplace Items',
                        }}
                        renderItem={(item) => {
                            const image = item.images.length ? item.images[0]!.url : undefined;
                            const { images, isPaymentNeeded, price, ...props } = item;
                            return (
                                <MarketplaceItem
                                    {...props}
                                    actions={[
                                        price && price > 0 && item.paymentUrl ? (
                                            <Button onClick={() => handlePaymentDetails(item.paymentUrl)} type="primary">
                                                Payment Details
                                            </Button>
                                        ) : null,
                                        <Button onClick={() => handleApproveClick(item.id, item.title)} type="primary">
                                            Approve
                                        </Button>,
                                        <Button danger onClick={() => handleRejectClick(item.id)}>
                                            Reject
                                        </Button>,
                                    ]}
                                    fullDescription
                                    image={image}
                                    images={item.images}
                                    isNavigable={false}
                                    price={price}
                                />
                            );
                        }}
                        size="large"
                    />
                </Card>
            </Layout>
        </>
    );
};

export { MarketplacePendingView };
