import { Alert, Button, Space, Tooltip, Typography } from 'antd';
import { Formik } from 'formik';
import { Checkbox, DatePicker, Form, Input, InputNumber, Radio, Select, SubmitButton, Switch } from 'formik-antd';
import moment from 'moment';
import { boolean, mixed, number, object, string } from 'yup';

import bereavement from '@/assets/bereavement.png';
import { BuyingSellingAdvice, Card } from '@/components/atoms';
import { ImageUpload } from '@/components/molecules';
import { LocationInput } from '@/forms/components';
import { marketplaceItemDescription, marketplaceItemImages } from '@/forms/validationSchema';
import { MarketplaceCategory, MarketplaceItemCondition, MarketplaceItemDelivery } from '@/graphql';
import { orderedCategories } from '@/utils';

import { MarketplaceNewContainerValues } from '../../MarketplaceNewContainer';

type Props = {
    categories: MarketplaceCategory[];
    initialValues: MarketplaceNewContainerValues;
    onBack: () => void;
    onSubmit: (values: MarketplaceNewContainerValues) => void;
};

const validationSchema = object().shape({
    title: string().max(255, 'Title must be 255 characters or less').required('Please enter a title').trim('Title must not include leading or trailing spaces'),
    categoryId: string().required('Please enter a category'),
    description: marketplaceItemDescription,
    expiresOn: string().nullable(),
    condition: string().required('Please enter a condition'),
    bereavement: boolean(),
    images: marketplaceItemImages,
    location: string().required('Please enter a location'),
    delivery: string().required('Please enter a delivery'),
    price: mixed().when('isFree', {
        is: false,
        then: number().typeError('Please enter a price').required('Please enter a price').min(0.01, 'Price must be £0.01 or more'),
    }),
});

const MarketplaceNewDetailsView = ({ categories, initialValues, onBack, onSubmit }: Props) => {
    const handleSubmit = ({ expiresOn, price, ...values }: MarketplaceNewContainerValues) => {
        onSubmit({
            ...values,
            expiresOn: expiresOn ? moment(expiresOn).startOf('day').toISOString() : undefined,
            price: values.isFree ? 0 : price,
        });
    };

    return (
        <>
            <Alert description={BuyingSellingAdvice.MESSAGE} message={BuyingSellingAdvice.TITLE} showIcon style={{ marginBottom: 24 }} type="info" />
            <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
                {({ values }) => (
                    <Form colon={false} labelCol={{ span: 5 }} wrapperCol={{ span: 14 }}>
                        <Card title="Details">
                            {/* Title */}
                            <Form.Item label="Title" name="title">
                                <Input fast name="title" />
                            </Form.Item>

                            {/* Category */}
                            <Form.Item label="Category" name="categoryId">
                                <Select fast name="categoryId">
                                    {orderedCategories(categories).map(({ id, name }) => (
                                        <Select.Option key={id} value={id}>
                                            {name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>

                            {/* Description */}
                            <Form.Item label="Description" name="description">
                                <Form.Item name="" noStyle>
                                    <Input.TextArea
                                        autoSize={{ minRows: 5 }}
                                        fast
                                        name="description"
                                        placeholder="1 box of 60ml enfit enteral syringes. 60 syringes in the box. Good condition as I haven't opened the box. 
                    Product can be posted out to you if you cover postage costs, or you can collect it from me. Please pay via PayPal or cash upon collection."
                                    />
                                </Form.Item>
                            </Form.Item>

                            <Form.Item label=" " name="description_alert">
                                <Alert
                                    description="If you group too many items together in one post, people will not be able to search for them accurately. To improve your chances of people finding your item(s), please photograph and post them individually."
                                    message="Posting Multiple Items?"
                                    showIcon
                                    // style={{ marginTop: 15 }}
                                    type="info"
                                />
                            </Form.Item>

                            {/* Expiry date */}
                            <Form.Item
                                label={
                                    <Space size={4}>
                                        Expiry date
                                        <Typography.Text type="secondary">- Optional</Typography.Text>
                                    </Space>
                                }
                                name="expiresOn"
                            >
                                <DatePicker disabledDate={(current) => current && current < moment().endOf('day')} fast format="DD/MM/YYYY" name="expiresOn" placeholder="Choose date" />
                            </Form.Item>

                            {/* Condition */}
                            <Form.Item label="Condition" name="condition">
                                <Radio.Group fast name="condition">
                                    <Radio name="condition" value={MarketplaceItemCondition.New}>
                                        New
                                    </Radio>
                                    <Radio name="condition" value={MarketplaceItemCondition.Unopened}>
                                        Unopened
                                    </Radio>
                                    <Radio name="condition" value={MarketplaceItemCondition.LikeNew}>
                                        Like new
                                    </Radio>
                                    <Radio name="condition" value={MarketplaceItemCondition.Used}>
                                        Used
                                    </Radio>
                                </Radio.Group>
                            </Form.Item>

                            {/* Bereavement */}
                            <Form.Item label={<img alt="Title" height={32} src={bereavement} width={32} />} name="bereavement">
                                <Form.Item name="" noStyle valuePropName="checked">
                                    <Checkbox fast name="bereavement" />
                                </Form.Item>
                                <Tooltip
                                    title={
                                        <>
                                            <p>It is a sad reality that sometimes equipment becomes available due to bereavement.</p>
                                            <p>Selecting this is a request for sensitivity from respondents.</p>
                                        </>
                                    }
                                >
                                    <Button type="link">Bereavement Angel: What is this?</Button>
                                </Tooltip>
                            </Form.Item>
                        </Card>

                        <Card title="Images (Required)">
                            <Form.Item label="Images" name="images">
                                <Form.Item name="" noStyle>
                                    <ImageUpload name="images" />
                                </Form.Item>
                            </Form.Item>

                            <Form.Item label=" " name="images_alert">
                                <Alert
                                    description="If you group too many items together in one post, people will not be able to search for them accurately. To improve your chances of people finding your item(s), please photograph and post them individually."
                                    message="Posting Multiple Items?"
                                    showIcon
                                    // style={{ marginTop: 15 }}
                                    type="info"
                                />
                                <Alert description="Skiggle does not allow stock images. Please upload a photograph of your own product. All posts that contain a stock image will be deleted by our administrators in line with our terms and conditions. " message="Stock Images" showIcon style={{ marginTop: 15 }} type="warning" />
                            </Form.Item>
                        </Card>

                        <Card title="Location">
                            {/* Location */}
                            <Form.Item label="Location" name="location">
                                <Form.Item name="" noStyle>
                                    {/* @ts-ignore */}
                                    <LocationInput name="location" />
                                </Form.Item>
                            </Form.Item>

                            <Form.Item label=" " name="location_alert">
                                <Alert
                                    description="To preserve your privacy, be as precise or general as you like. E.g. 'Devon, UK' or 'SW4'"
                                    message="This Will Be Shown on Your Post"
                                    showIcon
                                    // style={{ marginTop: 15 }}
                                    type="info"
                                />
                            </Form.Item>
                        </Card>

                        <Card title="Delivery">
                            {/* Delivery */}
                            <Form.Item label="Delivery" name="delivery">
                                <Radio.Group fast name="delivery">
                                    <Radio name="delivery" value={MarketplaceItemDelivery.Included}>
                                        Postage included
                                    </Radio>
                                    <Radio name="delivery" value={MarketplaceItemDelivery.NotIncluded}>
                                        Postage not included
                                    </Radio>
                                    <Radio name="delivery" value={MarketplaceItemDelivery.Collection}>
                                        Collection only
                                    </Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Card>

                        <Card
                            extra={
                                <Space>
                                    <Typography.Text strong>Free</Typography.Text>
                                    <Form.Item name="isFree" noStyle valuePropName="checked">
                                        <Switch name="isFree" />
                                    </Form.Item>
                                </Space>
                            }
                            title="Price"
                        >
                            {/* Price */}
                            <Form.Item name="" noStyle>
                                {!values.isFree && (
                                    <Form.Item
                                        label={
                                            <Space size={4}>
                                                Price
                                                <Typography.Text strong>- £</Typography.Text>
                                            </Space>
                                        }
                                        name="price"
                                    >
                                        <InputNumber max={999999} min={0.01} name="price" precision={2} step={0.01} />
                                    </Form.Item>
                                )}
                            </Form.Item>
                        </Card>

                        <Form.Item name="controls" wrapperCol={{ offset: 10 }}>
                            <Space size="large">
                                <Button onClick={onBack} size="large">
                                    Back
                                </Button>
                                <SubmitButton size="large">Next</SubmitButton>
                            </Space>
                        </Form.Item>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export { MarketplaceNewDetailsView };
