import { EnvironmentOutlined, TagOutlined } from '@ant-design/icons';
import { Image, List, Tag, Typography } from 'antd';
import moment from 'moment';
import { ReactNode, useState } from 'react';
import { Link } from 'react-router-dom';

import { MarketplaceCategory, MarketplaceItemStatus } from '@/graphql';

import styles from './MarketplaceItem.module.css';
import placeholderImage from './placeholder.png';

type Props = {
    actions?: ReactNode[];
    category?: MarketplaceCategory | null;
    createdAt: string;
    listingDate?: string;
    description: string;
    fullDescription?: boolean;
    id?: string;
    image?: string;
    isNavigable?: boolean;
    location?: string | null;
    price?: number | null;
    status: MarketplaceItemStatus;
    title: string;
    images?: any[];
};

const MarketplaceItem = ({ actions = [], category, createdAt, listingDate, description, fullDescription = false, id, image = placeholderImage, isNavigable = true, location, price, status, title, images }: Props) => {
    const categoryElement = category && (
        <Tag className={styles.category} color="blue" key={`category-${title}`}>
            {category.name}
        </Tag>
    );
    const locationElement = location ? (
        <span className={styles.location}>
            <EnvironmentOutlined /> {location}
        </span>
    ) : null;
    const postedPrefix = status === MarketplaceItemStatus.Approved ? 'Posted' : 'Submitted';
    const postedElement =
        createdAt || listingDate ? (
            <>
                {postedPrefix} {listingDate ? moment(listingDate).fromNow() : moment(createdAt).fromNow()}
            </>
        ) : undefined;
    const paragraphProps = fullDescription ? {} : { ellipsis: { rows: 2 } };
    const [visible, setVisible] = useState(false);
    return (
        <List.Item
            actions={[postedElement, ...actions]}
            className={styles.item}
            extra={
                <>
                    <Image alt="Item" className={styles.image} height={180} onClick={() => setVisible(true)} preview={{ visible: false }} src={image} width={180} />
                    <div style={{ display: 'none' }}>
                        <Image.PreviewGroup preview={{ visible, onVisibleChange: (vis) => setVisible(vis) }}>
                            {images?.map((image_) => {
                                return <Image key={image_.url} src={image_.url} />;
                            })}
                        </Image.PreviewGroup>
                    </div>
                </>
            }
            key={`list-item-${title}`}
        >
            <Link className={id && isNavigable ? undefined : styles.disableLink} to={id ? `/marketplace/${id}` : '#'}>
                <List.Item.Meta
                    description={[
                        <Typography.Title className={styles.title} key={`title-${title}`} level={4}>
                            {title}
                        </Typography.Title>,

                        categoryElement,
                    ]}
                    title={
                        <>
                            {locationElement}
                            <TagOutlined /> {price ? `£${(price / 100).toFixed(2)}` : 'Free'}
                        </>
                    }
                />
                <Typography.Paragraph {...paragraphProps}>{description}</Typography.Paragraph>
            </Link>
        </List.Item>
    );
};

export { MarketplaceItem };
