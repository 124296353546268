import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
    Date: any;
    /** A time string at UTC, such as 10:15:30Z, compliant with the `full-time` format outlined in section 5.6 of the RFC 3339profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
    Time: any;
    /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
    DateTime: any;
    /** The javascript `Date` as integer. Type represents date and time as number of milliseconds from start of UNIX epoch. */
    Timestamp: any;
    /** A field whose value is a UTC Offset: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones */
    UtcOffset: any;
    /**
     *
     *     A string representing a duration conforming to the ISO8601 standard,
     *     such as: P1W1DT13H23M34S
     *     P is the duration designator (for period) placed at the start of the duration representation.
     *     Y is the year designator that follows the value for the number of years.
     *     M is the month designator that follows the value for the number of months.
     *     W is the week designator that follows the value for the number of weeks.
     *     D is the day designator that follows the value for the number of days.
     *     T is the time designator that precedes the time components of the representation.
     *     H is the hour designator that follows the value for the number of hours.
     *     M is the minute designator that follows the value for the number of minutes.
     *     S is the second designator that follows the value for the number of seconds.
     *
     *     Note the time designator, T, that precedes the time value.
     *
     *     Matches moment.js, Luxon and DateFns implementations
     *     ,/. is valid for decimal places and +/- is a valid prefix
     *
     */
    ISO8601Duration: any;
    /** A field whose value conforms to the standard internet email address format as specified in RFC822: https://www.w3.org/Protocols/rfc822/. */
    EmailAddress: any;
    /** Floats that will have a value less than 0. */
    NegativeFloat: any;
    /** Integers that will have a value less than 0. */
    NegativeInt: any;
    /** A string that cannot be passed as an empty value */
    NonEmptyString: any;
    /** Floats that will have a value of 0 or more. */
    NonNegativeFloat: any;
    /** Integers that will have a value of 0 or more. */
    NonNegativeInt: any;
    /** Floats that will have a value of 0 or less. */
    NonPositiveFloat: any;
    /** Integers that will have a value of 0 or less. */
    NonPositiveInt: any;
    /** A field whose value conforms to the standard E.164 format as specified in: https://en.wikipedia.org/wiki/E.164. Basically this is +17895551234. */
    PhoneNumber: any;
    /** Floats that will have a value greater than 0. */
    PositiveFloat: any;
    /** Integers that will have a value greater than 0. */
    PositiveInt: any;
    /** A field whose value conforms to the standard postal code formats for United States, United Kingdom, Germany, Canada, France, Italy, Australia, Netherlands, Spain, Denmark, Sweden, Belgium, India, Austria, Portugal, Switzerland or Luxembourg. */
    PostalCode: any;
    /** Floats that will have a value of 0 or more. */
    UnsignedFloat: any;
    /** Integers that will have a value of 0 or more. */
    UnsignedInt: any;
    /** A field whose value conforms to the standard URL format as specified in RFC3986: https://www.ietf.org/rfc/rfc3986.txt. */
    URL: any;
    /** The `BigInt` scalar type represents non-fractional signed whole numeric values. */
    BigInt: any;
    /** The `BigInt` scalar type represents non-fractional signed whole numeric values. */
    Long: any;
    /** The `Byte` scalar type represents byte value as a Buffer */
    Byte: any;
    /** A field whose value is a generic Universally Unique Identifier: https://en.wikipedia.org/wiki/Universally_unique_identifier. */
    UUID: any;
    /** A field whose value is a generic Universally Unique Identifier: https://en.wikipedia.org/wiki/Universally_unique_identifier. */
    GUID: any;
    /** A field whose value is a hexadecimal: https://en.wikipedia.org/wiki/Hexadecimal. */
    Hexadecimal: any;
    /** A field whose value is a hex color code: https://en.wikipedia.org/wiki/Web_colors. */
    HexColorCode: any;
    /** A field whose value is a CSS HSL color: https://developer.mozilla.org/en-US/docs/Web/CSS/color_value#hsl()_and_hsla(). */
    HSL: any;
    /** A field whose value is a CSS HSLA color: https://developer.mozilla.org/en-US/docs/Web/CSS/color_value#hsl()_and_hsla(). */
    HSLA: any;
    /** A field whose value is a IPv4 address: https://en.wikipedia.org/wiki/IPv4. */
    IPv4: any;
    /** A field whose value is a IPv6 address: https://en.wikipedia.org/wiki/IPv6. */
    IPv6: any;
    /** A field whose value is a ISBN-10 or ISBN-13 number: https://en.wikipedia.org/wiki/International_Standard_Book_Number. */
    ISBN: any;
    /** A field whose value is a IEEE 802 48-bit MAC address: https://en.wikipedia.org/wiki/MAC_address. */
    MAC: any;
    /** A field whose value is a valid TCP port within the range of 0 to 65535: https://en.wikipedia.org/wiki/Transmission_Control_Protocol#TCP_ports */
    Port: any;
    /** A field whose value is a CSS RGB color: https://developer.mozilla.org/en-US/docs/Web/CSS/color_value#rgb()_and_rgba(). */
    RGB: any;
    /** A field whose value is a CSS RGBA color: https://developer.mozilla.org/en-US/docs/Web/CSS/color_value#rgb()_and_rgba(). */
    RGBA: any;
    /** The `SafeInt` scalar type represents non-fractional signed whole numeric values that are considered safe as defined by the ECMAScript specification. */
    SafeInt: any;
    /** A currency string, such as $21.25 */
    USCurrency: any;
    /** A field whose value is a Currency: https://en.wikipedia.org/wiki/ISO_4217. */
    Currency: any;
    /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
    JSON: any;
    /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
    JSONObject: any;
    /** A field whose value is an International Bank Account Number (IBAN): https://en.wikipedia.org/wiki/International_Bank_Account_Number. */
    IBAN: any;
    /** A field whose value conforms with the standard mongodb object ID as described here: https://docs.mongodb.com/manual/reference/method/ObjectId/#ObjectId. Example: 5e5677d71bdc2ae76344968c */
    ObjectID: any;
    /** Represents NULL values */
    Void: any;
    /** The `Upload` scalar type represents a file upload. */
    Upload: any;
};

export type Query = {
    __typename?: 'Query';
    marketplaceCategories: Array<MarketplaceCategory>;
    marketplaceItem?: Maybe<MarketplaceItem>;
    marketplaceItems: Array<MarketplaceItem>;
    sos: Array<Sos>;
    sosById?: Maybe<Sos>;
    sosCount?: Maybe<Scalars['Int']>;
    sosCountDays?: Maybe<Array<Maybe<Scalars['Int']>>>;
    sosCountMonths?: Maybe<Array<Maybe<Scalars['Int']>>>;
    sosCost?: Maybe<Scalars['Float']>;
    me?: Maybe<User>;
    usersCount?: Maybe<Scalars['Int']>;
    usersCountDays?: Maybe<Array<Maybe<Scalars['Int']>>>;
    usersCountMonths?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type QueryMarketplaceItemArgs = {
    id: Scalars['ID'];
};

export type QueryMarketplaceItemsArgs = {
    filter?: Maybe<MarketplaceItemsFilterInput>;
};

export type QuerySosByIdArgs = {
    id: Scalars['ID'];
};

export type QuerySosCountDaysArgs = {
    days: Scalars['Int'];
};

export type QuerySosCountMonthsArgs = {
    months: Scalars['Int'];
};

export type QueryUsersCountDaysArgs = {
    days: Scalars['Int'];
};

export type QuerySosCostArgs = {
  long: Scalars['Float'];
  lat: Scalars['Float'];
  distance: Scalars['Int'];
};

export type QueryUsersCountMonthsArgs = {
    months: Scalars['Int'];
};

export type Mutation = {
    __typename?: 'Mutation';
    approveMarketplaceItem?: Maybe<MarketplaceItem>;
    createMarketplaceItem: MarketplaceItem;
    deleteMarketplaceItem?: Maybe<MarketplaceItem>;
    deleteMarketplaceItemAsAdmin?: Maybe<MarketplaceItem>;
    editMarketplaceItem: MarketplaceItem;
    rejectMarketplaceItem?: Maybe<MarketplaceItem>;
    renewMarketplaceItem?: Maybe<MarketplaceItem>;
    sendMarketplaceItemContactMessage: Scalars['Boolean'];
    approveSos?: Maybe<Sos>;
    createSos?: Maybe<Sos>;
    deleteSos?: Maybe<Sos>;
    editSos?: Maybe<Sos>;
    rejectSos?: Maybe<Sos>;
    submitSosSurvey: Scalars['Boolean'];
    checkoutSos: Scalars['String'];
    paidSos: Scalars['Int'];
    deleteMe?: Maybe<Scalars['Boolean']>;
    favouriteMarketplaceItem?: Maybe<User>;
    forgotPassword?: Maybe<Scalars['Boolean']>;
    login?: Maybe<User>;
    logout: Scalars['Boolean'];
    removeFavouriteMarketplaceItem?: Maybe<User>;
    resendVerifyEmail?: Maybe<Scalars['Boolean']>;
    resendVerifyMobile?: Maybe<Scalars['Boolean']>;
    resetPassword?: Maybe<Scalars['Boolean']>;
    signUp?: Maybe<User>;
    updateMe?: Maybe<User>;
    verifyEmail?: Maybe<Scalars['Boolean']>;
    verifyMobile?: Maybe<Scalars['Boolean']>;
};

export type MutationApproveMarketplaceItemArgs = {
    id: Scalars['ID'];
};

export type MutationCreateMarketplaceItemArgs = {
    input: CreateMarketplaceItemInput;
};

export type MutationDeleteMarketplaceItemArgs = {
    id: Scalars['ID'];
};

export type MutationDeleteMarketplaceItemAsAdminArgs = {
    id: Scalars['ID'];
    reason: Scalars['String'];
    feedback?: Maybe<Scalars['String']>;
};

export type MutationEditMarketplaceItemArgs = {
    input: EditMarketplaceItemInput;
};

export type MutationRejectMarketplaceItemArgs = {
    id: Scalars['ID'];
    reason: Scalars['String'];
    feedback?: Maybe<Scalars['String']>;
};

export type MutationRenewMarketplaceItemArgs = {
    id: Scalars['ID'];
};

export type MutationSendMarketplaceItemContactMessageArgs = {
    input: SendMarketplaceItemContactMessageInput;
};

export type MutationApproveSosArgs = {
    id: Scalars['ID'];
};

export type MutationCreateSosArgs = {
    input: CreateSosInput;
};

export type MutationDeleteSosArgs = {
    id: Scalars['ID'];
};

export type MutationEditSosArgs = {
    input: EditSosInput;
};

export type MutationRejectSosArgs = {
    id: Scalars['ID'];
    reason: Scalars['String'];
    feedback?: Maybe<Scalars['String']>;
};

export type MutationSubmitSosSurveyArgs = {
    token: Scalars['String'];
    feedback: Scalars['Boolean'];
};

export type MutationCheckoutSosArgs = {
  id: Scalars['ID'];
};


export type MutationPaidSosArgs = {
  id: Scalars['ID'];
  price: Scalars['Int'];
  session: Scalars['String'];
  session_id: Scalars['String'];
};


export type MutationFavouriteMarketplaceItemArgs = {
    id: Scalars['ID'];
};

export type MutationForgotPasswordArgs = {
    email: Scalars['String'];
};

export type MutationLoginArgs = {
    email: Scalars['String'];
    password: Scalars['String'];
};

export type MutationRemoveFavouriteMarketplaceItemArgs = {
    id: Scalars['ID'];
};

export type MutationResetPasswordArgs = {
    token: Scalars['String'];
    password: Scalars['String'];
};

export type MutationSignUpArgs = {
    input: SignUpInput;
};

export type MutationUpdateMeArgs = {
    input: UpdateMeInput;
};

export type MutationVerifyEmailArgs = {
    token: Scalars['String'];
};

export type MutationVerifyMobileArgs = {
    code: Scalars['String'];
};

export type Image = {
    __typename?: 'Image';
    id: Scalars['ID'];
    uuid: Scalars['String'];
    url: Scalars['String'];
};

export type MarketplaceCategory = {
    __typename?: 'MarketplaceCategory';
    id: Scalars['ID'];
    name: Scalars['String'];
};

export enum MarketplaceItemCondition {
    New = 'NEW',
    Unopened = 'UNOPENED',
    LikeNew = 'LIKE_NEW',
    Used = 'USED',
}

export enum MarketplaceItemDelivery {
    Included = 'INCLUDED',
    NotIncluded = 'NOT_INCLUDED',
    Collection = 'COLLECTION',
}

export enum MarketplaceItemStatus {
    Approved = 'APPROVED',
    ChangeRequired = 'CHANGE_REQUIRED',
    New = 'NEW',
}

export type CreateMarketplaceItemInput = {
    bereavement?: Maybe<Scalars['Boolean']>;
    categoryId: Scalars['ID'];
    condition: MarketplaceItemCondition;
    delivery?: Maybe<MarketplaceItemDelivery>;
    description: Scalars['String'];
    expiresOn?: Maybe<Scalars['DateTime']>;
    images: Array<Scalars['Upload']>;
    location?: Maybe<Scalars['String']>;
    price?: Maybe<Scalars['Int']>;
    title: Scalars['String'];
};

export type EditMarketplaceItemInput = {
    bereavement?: Maybe<Scalars['Boolean']>;
    categoryId: Scalars['ID'];
    condition: MarketplaceItemCondition;
    delivery?: Maybe<MarketplaceItemDelivery>;
    description: Scalars['String'];
    expiresOn?: Maybe<Scalars['DateTime']>;
    id: Scalars['ID'];
    images: Array<MarketplaceItemImage>;
    imagesNew: Array<Scalars['Upload']>;
    location?: Maybe<Scalars['String']>;
    price?: Maybe<Scalars['Int']>;
    title: Scalars['String'];
};

export type MarketplaceItemsFilterInput = {
    categoryId?: Maybe<Scalars['ID']>;
    location?: Maybe<Scalars['String']>;
    title?: Maybe<Scalars['String']>;
    isItemPaid?: Maybe<Scalars['Boolean']>;
    status: MarketplaceItemStatus;
};

export type MarketplaceItemImage = {
    id: Scalars['ID'];
};

export type RenewMarketplaceItemInput = {
    id: Scalars['ID'];
};

export type SendMarketplaceItemContactMessageInput = {
    id: Scalars['ID'];
    message?: Maybe<Scalars['String']>;
    shareMyEmail: Scalars['Boolean'];
    shareMyMobile: Scalars['Boolean'];
    shareMyName: Scalars['Boolean'];
};

export type MarketplaceItem = {
    __typename?: 'MarketplaceItem';
    bereavement: Scalars['Boolean'];
    category?: Maybe<MarketplaceCategory>;
    condition: MarketplaceItemCondition;
    createdAt: Scalars['DateTime'];
    deleteAt: Scalars['DateTime'];
    delivery?: Maybe<MarketplaceItemDelivery>;
    description: Scalars['String'];
    expiresOn?: Maybe<Scalars['DateTime']>;
    id: Scalars['ID'];
    images: Array<Maybe<Image>>;
    isRenewable?: Maybe<Scalars['Boolean']>;
    location?: Maybe<Scalars['String']>;
    price?: Maybe<Scalars['Int']>;
    status: MarketplaceItemStatus;
    title: Scalars['String'];
    listingDate?: Scalars['DateTime'];
    isPaymentNeeded: Scalars['Boolean'];
    paymentUrl: Scalars['String'];
};

export enum RoleType {
    Admin = 'ADMIN',
}

export type Role = {
    __typename?: 'Role';
    id: Scalars['ID'];
    type: RoleType;
    name: Scalars['String'];
    description: Scalars['String'];
};

export enum SosStatus {
    Approved = 'APPROVED',
    Pending = 'PENDING',
    Rejected = 'REJECTED',
}

export enum SosWhy {
    AtHome = 'AT_HOME',
    InHospital = 'IN_HOSPITAL',
    LateStockDelivery = 'LATE_STOCK_DELIVERY',
    OnADayOut = 'ON_A_DAY_OUT',
    OnHoliday = 'ON_HOLIDAY',
    Other = 'OTHER',
}

export type CreateSosInput = {
    distance: Scalars['Int'];
    latitude: Scalars['Float'];
    longitude: Scalars['Float'];
    location: Scalars['String'];
    message?: Maybe<Scalars['String']>;
    why: SosWhy;
};

export type EditSosInput = {
    id: Scalars['ID'];
    message: Scalars['String'];
};

export type PaymentBreakdown = {
    __typename?: 'PaymentBreakdown';
    rate: Scalars['Float'];
    total: Scalars['Float'];
    cardFee: Scalars['Float'];
    smsCost: Scalars['Float'];
    twillioGbp: Scalars['Float'];
    twilllioFee: Scalars['Float']; 
    potntialTotal: Scalars['Float'];
    twilllioSellingFee: Scalars['Float'];
}

export type Sos = {
    __typename?: 'Sos';
    createdAt: Scalars['DateTime'];
    distance: Scalars['Int'];
    id: Scalars['ID'];
    latitude: Scalars['Float'];
    longitude: Scalars['Float'];
    isDeleted: Scalars['Boolean'];
    isItemPaid: Scalars['Boolean'];
    paidPrice: Scalars['Float'];
    message: Scalars['String'];
    paymentBreakdown: PaymentBreakdown;
    status: SosStatus;
    surveyFeedback?: Maybe<Scalars['Boolean']>;
    why: SosWhy;
    user: Pick<User, 'email' | 'mobile' | 'name' | 'mySos'>;
};

export type SignUpInput = {
    email: Scalars['String'];
    latitude: Scalars['Float'];
    location: Scalars['String'];
    longitude: Scalars['Float'];
    mobile: Scalars['String'];
    name: Scalars['String'];
    password: Scalars['String'];
    sosReceive: Scalars['Boolean'];
};

export type UpdateMeInput = {
    email: Scalars['String'];
    image?: Maybe<Scalars['Upload']>;
    latitude: Scalars['Float'];
    location: Scalars['String'];
    longitude: Scalars['Float'];
    mobile: Scalars['String'];
    name: Scalars['String'];
    password?: Maybe<Scalars['String']>;
    sosReceive: Scalars['Boolean'];
    sosReceiveEmail: Scalars['Boolean'];
    sosReceiveMobile: Scalars['Boolean'];
};

export type User = {
    __typename?: 'User';
    createdAt: Scalars['DateTime'];
    email: Scalars['String'];
    emailVerified: Scalars['Boolean'];
    favouriteMarketplaceItems: Array<Maybe<MarketplaceItem>>;
    id: Scalars['ID'];
    image?: Maybe<Image>;
    location: Scalars['String'];
    locked: Scalars['Boolean'];
    lockedReason?: Maybe<Scalars['String']>;
    mobile: Scalars['String'];
    mobileVerified: Scalars['Boolean'];
    myMarketplaceItems: Array<Maybe<MarketplaceItem>>;
    mySos: Array<Sos>;
    name: Scalars['String'];
    roles: Array<Role>;
    sosReceive: Scalars['Boolean'];
    sosReceiveEmail: Scalars['Boolean'];
    sosReceiveMobile: Scalars['Boolean'];
};

export type AdminSosListContainerQueryVariables = Exact<{ [key: string]: never }>;

export type AdminSosListContainerQuery = { __typename?: 'Query' } & Pick<Query, 'sosCount' | 'sosCountDays' | 'sosCountMonths'> & {
    sos: Array<{ __typename?: 'Sos' } & Pick<Sos, 'createdAt' | 'distance' | 'latitude' | 'longitude' | 'message' | 'status' | 'surveyFeedback'>>;
};

export type MarketplaceEditContainerQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type MarketplaceEditContainerQuery = { __typename?: 'Query' } & {
    marketplaceCategories: Array<{ __typename?: 'MarketplaceCategory' } & Pick<MarketplaceCategory, 'id' | 'name'>>;
    marketplaceItem?: Maybe<
        { __typename?: 'MarketplaceItem' } & Pick<MarketplaceItem, 'bereavement' | 'condition' | 'delivery' | 'description' | 'expiresOn' | 'id' | 'location' | 'price' | 'title' | 'status'> & {
            category?: Maybe<{ __typename?: 'MarketplaceCategory' } & Pick<MarketplaceCategory, 'id' | 'name'>>;
            images: Array<Maybe<{ __typename?: 'Image' } & Pick<Image, 'id' | 'uuid' | 'url'>>>;
        }
    >;
};

export type MarketplaceEditContainerEditMarketplaceItemMutationVariables = Exact<{
    input: EditMarketplaceItemInput;
}>;

export type MarketplaceEditContainerEditMarketplaceItemMutation = { __typename?: 'Mutation' } & {
    editMarketplaceItem: { __typename?: 'MarketplaceItem' } & Pick<MarketplaceItem, 'description' | 'id' | 'location' | 'price' | 'title' | 'status'> & {
        images: Array<Maybe<{ __typename?: 'Image' } & Pick<Image, 'id' | 'uuid' | 'url'>>>;
    };
};

export type MarketplaceMyItemsContainerQueryVariables = Exact<{ [key: string]: never }>;

export type MarketplaceMyItemsContainerQuery = { __typename?: 'Query' } & {
    me?: Maybe<
        { __typename?: 'User' } & Pick<User, 'id'> & {
            myMarketplaceItems: Array<
                Maybe<
                    { __typename?: 'MarketplaceItem' } & Pick<MarketplaceItem, 'createdAt' | 'deleteAt' | 'description' | 'id' | 'isRenewable' | 'location' | 'price' | 'status' | 'title' | 'listingDate' | 'isPaymentNeeded'> & {
                        category?: Maybe<{ __typename?: 'MarketplaceCategory' } & Pick<MarketplaceCategory, 'id' | 'name'>>;
                        images: Array<Maybe<{ __typename?: 'Image' } & Pick<Image, 'id' | 'uuid' | 'url'>>>;
                    }
                >
            >;
        }
    >;
};

export type MarketplaceMyItemsContainerDeleteMarketplaceItemMutationVariables = Exact<{
    id: Scalars['ID'];
}>;

export type MarketplaceMyItemsContainerDeleteMarketplaceItemMutation = { __typename?: 'Mutation' } & {
    deleteMarketplaceItem?: Maybe<{ __typename?: 'MarketplaceItem' } & Pick<MarketplaceItem, 'id'>>;
};

export type MarketplaceMyItemsContainerRenewMarketplaceItemMutationVariables = Exact<{
    id: Scalars['ID'];
}>;

export type MarketplaceMyItemsContainerPaidMarketplaceItemMutationVariables = Exact<{
    id: Scalars['ID'];
    session: Scalars['ID'];
    session_id: Scalars['ID'];
    price: Scalars['Int'];
}>;

export type MarketplaceMyItemsContainerCheckoutMarketplaceItemMutationVariables = Exact<{
    id: Scalars['ID'];
}>;

export type MarketplaceMyItemsContainerRenewMarketplaceItemMutation = { __typename?: 'Mutation' } & {
    renewMarketplaceItem?: Maybe<{ __typename?: 'MarketplaceItem' } & Pick<MarketplaceItem, 'deleteAt' | 'id' | 'isRenewable'>>;
};

export type MarketplaceMyItemsContainerPaidMarketplaceItemMutation = { __typename?: 'Mutation' } & {
    paidMarketplaceItem?: Maybe<{ __typename?: 'MarketplaceItem' } & Pick<MarketplaceItem, 'deleteAt' | 'id' | 'isRenewable'>>;
};

export type MarketplaceMyItemsContainerRelistMarketplaceItemMutationVariables = Exact<{
    id: Scalars['ID'];
}>;

export type MarketplaceMyItemsContainerRelistMarketplaceItemMutation = { __typename?: 'Mutation' } & {
    renewMarketplaceItem?: Maybe<{ __typename?: 'MarketplaceItem' } & Pick<MarketplaceItem, 'deleteAt' | 'id' | 'isRenewable'>>;
};

export type MarketplaceNewContainerCreateMarketplaceItemMutationVariables = Exact<{
    input: CreateMarketplaceItemInput;
}>;

export type MarketplaceNewContainerCreateMarketplaceItemMutation = { __typename?: 'Mutation' } & {
    createMarketplaceItem: { __typename?: 'MarketplaceItem' } & Pick<MarketplaceItem, 'id'>;
};

export type MarketplaceNewContainerCheckoutMarketplaceItemMutation = { __typename?: 'Mutation' } & {
    checkoutMarketplaceItem: Scalars['String'];
};

export type MarketplaceNewDetailsContainerQueryVariables = Exact<{ [key: string]: never }>;

export type MarketplaceNewDetailsContainerQuery = { __typename?: 'Query' } & {
    marketplaceCategories: Array<{ __typename?: 'MarketplaceCategory' } & Pick<MarketplaceCategory, 'id' | 'name'>>;
};

export type MarketplacePendingContainerQueryVariables = Exact<{
    filter?: Maybe<MarketplaceItemsFilterInput>;
}>;

export type MarketplacePendingContainerQuery = { __typename?: 'Query' } & {
    marketplaceItems: Array<
        { __typename?: 'MarketplaceItem' } & Pick<MarketplaceItem, 'createdAt' | 'description' | 'id' | 'location' | 'price' | 'status' | 'title' | 'listingDate' | 'isPaymentNeeded' | 'paymentUrl'> & {
            category?: Maybe<{ __typename?: 'MarketplaceCategory' } & Pick<MarketplaceCategory, 'id' | 'name'>>;
            images: Array<Maybe<{ __typename?: 'Image' } & Pick<Image, 'id' | 'uuid' | 'url'>>>;
        }
    >;
};

export type MarketplacePendingContainerApproveMarketplaceItemMutationVariables = Exact<{
    id: Scalars['ID'];
}>;

export type MarketplacePendingContainerApproveMarketplaceItemMutation = { __typename?: 'Mutation' } & {
    approveMarketplaceItem?: Maybe<{ __typename?: 'MarketplaceItem' } & Pick<MarketplaceItem, 'id' | 'status'>>;
};

export type MarketplacePendingContainerRejectMarketplaceItemMutationVariables = Exact<{
    id: Scalars['ID'];
    reason: Scalars['String'];
    feedback?: Maybe<Scalars['String']>;
}>;

export type MarketplacePendingContainerRejectMarketplaceItemMutation = { __typename?: 'Mutation' } & {
    rejectMarketplaceItem?: Maybe<{ __typename?: 'MarketplaceItem' } & Pick<MarketplaceItem, 'id' | 'status'>>;
};

export type SosEditContainerQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type SosEditContainerQuery = { __typename?: 'Query' } & {
    sosById?: Maybe<{ __typename?: 'Sos' } & Pick<Sos, 'id' | 'message' | 'status'>>;
};

export type SosEditContainerEditSosItemMutationVariables = Exact<{
    input: EditSosInput;
}>;

export type SosEditContainerEditSosItemMutation = { __typename?: 'Mutation' } & {
    editSos?: Maybe<{ __typename?: 'Sos' } & Pick<Sos, 'id' | 'message' | 'status'>>;
};

export type SosMySosContainerQueryVariables = Exact<{ [key: string]: never }>;

export type SosMySosContainerQuery = { __typename?: 'Query' } & {
    me?: Maybe<
        { __typename?: 'User' } & Pick<User, 'id'> & {
            mySos: Array<Maybe<{ __typename?: 'Sos' } & Pick<Sos, 'createdAt' | 'distance' | 'id' | 'message' | 'status'>>>;
        }
    >;
};

export type SosMySosContainerDeleteSosMutationVariables = Exact<{
    id: Scalars['ID'];
}>;

export type CheckoutMarketPlaceItem = Exact<{
    id: Scalars['ID'];
}>;

export type SosMySosContainerDeleteSosMutation = { __typename?: 'Mutation' } & {
    deleteSos?: Maybe<{ __typename?: 'Sos' } & Pick<Sos, 'id'>>;
};

export type SosMySosContainerPaidSosMutationVariables = Exact<{
  id: Scalars['ID'];
  session: Scalars['String'];
  session_id: Scalars['String'];
  price: Scalars['Int'];
}>;

export type SosMySosContainerPaidSosMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'paidSos'>
);

export type SosNewContainerSosCostQueryVariables = Exact<{
    long: Scalars['Float'];
    lat: Scalars['Float'];
    distance: Scalars['Int'];
  }>;
  

export type SosNewContainerSosCostQuery = ({ __typename?: 'Query' }
    & Pick<Query, 'sosCost'>
);


export type SosNewContainerCreateSosMutationVariables = Exact<{
    input: CreateSosInput;
}>;

export type SosNewContainerCreateSosMutation = { __typename?: 'Mutation' } & {
    createSos?: Maybe<{ __typename?: 'Sos' } & Pick<Sos, 'id'>>;
};

export type SosNewContainerCheckoutSosMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SosNewContainerCheckoutSosMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'checkoutSos'>
);


export type SosPendingContainerQueryVariables = Exact<{ [key: string]: never }>;

export type SosPendingContainerQuery = { __typename?: 'Query' } & {
    sos: Array<{ __typename?: 'Sos' } & Pick<Sos, 'createdAt' | 'distance' | 'id' | 'message' | 'status' | 'user' | 'isItemPaid' | 'paidPrice' | 'paymentBreakdown'>>;
};

export type SosPendingContainerApproveSosMutationVariables = Exact<{
    id: Scalars['ID'];
}>;

export type SosPendingContainerApproveSosMutation = { __typename?: 'Mutation' } & {
    approveSos?: Maybe<{ __typename?: 'Sos' } & Pick<Sos, 'id' | 'status'>>;
};

export type SosPendingContainerRejectSosMutationVariables = Exact<{
    id: Scalars['ID'];
    reason: Scalars['String'];
    feedback?: Maybe<Scalars['String']>;
}>;

export type SosPendingContainerRejectSosMutation = { __typename?: 'Mutation' } & {
    rejectSos?: Maybe<{ __typename?: 'Sos' } & Pick<Sos, 'id' | 'status'>>;
};

export type SosPendingContainerSubmitSosSurveyMutationVariables = Exact<{
    token: Scalars['String'];
    feedback: Scalars['Boolean'];
}>;

export type SosPendingContainerSubmitSosSurveyMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'submitSosSurvey'>;

export const AdminSosListContainerDocument = gql`
    query AdminSosListContainer {
        sos {
            createdAt
            distance
            latitude
            longitude
            message
            status
            surveyFeedback
        }
        sosCount
        sosCountDays(days: 30)
        sosCountMonths(months: 12)
    }
`;

/**
 * __useAdminSosListContainerQuery__
 *
 * To run a query within a React component, call `useAdminSosListContainerQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminSosListContainerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminSosListContainerQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminSosListContainerQuery(baseOptions?: Apollo.QueryHookOptions<AdminSosListContainerQuery, AdminSosListContainerQueryVariables>) {
    return Apollo.useQuery<AdminSosListContainerQuery, AdminSosListContainerQueryVariables>(AdminSosListContainerDocument, baseOptions);
}
export function useAdminSosListContainerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminSosListContainerQuery, AdminSosListContainerQueryVariables>) {
    return Apollo.useLazyQuery<AdminSosListContainerQuery, AdminSosListContainerQueryVariables>(AdminSosListContainerDocument, baseOptions);
}
export type AdminSosListContainerQueryHookResult = ReturnType<typeof useAdminSosListContainerQuery>;
export type AdminSosListContainerLazyQueryHookResult = ReturnType<typeof useAdminSosListContainerLazyQuery>;
export type AdminSosListContainerQueryResult = Apollo.QueryResult<AdminSosListContainerQuery, AdminSosListContainerQueryVariables>;
export const MarketplaceEditContainerDocument = gql`
    query MarketplaceEditContainer($id: ID!) {
        marketplaceCategories {
            id
            name
        }
        marketplaceItem(id: $id) {
            bereavement
            category {
                id
                name
            }
            condition
            delivery
            description
            expiresOn
            id
            images {
                id
                uuid
                url
            }
            location
            price
            paidPrice
            title
            status
        }
    }
`;

/**
 * __useMarketplaceEditContainerQuery__
 *
 * To run a query within a React component, call `useMarketplaceEditContainerQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketplaceEditContainerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketplaceEditContainerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMarketplaceEditContainerQuery(baseOptions: Apollo.QueryHookOptions<MarketplaceEditContainerQuery, MarketplaceEditContainerQueryVariables>) {
    return Apollo.useQuery<MarketplaceEditContainerQuery, MarketplaceEditContainerQueryVariables>(MarketplaceEditContainerDocument, baseOptions);
}
export function useMarketplaceEditContainerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MarketplaceEditContainerQuery, MarketplaceEditContainerQueryVariables>) {
    return Apollo.useLazyQuery<MarketplaceEditContainerQuery, MarketplaceEditContainerQueryVariables>(MarketplaceEditContainerDocument, baseOptions);
}
export type MarketplaceEditContainerQueryHookResult = ReturnType<typeof useMarketplaceEditContainerQuery>;
export type MarketplaceEditContainerLazyQueryHookResult = ReturnType<typeof useMarketplaceEditContainerLazyQuery>;
export type MarketplaceEditContainerQueryResult = Apollo.QueryResult<MarketplaceEditContainerQuery, MarketplaceEditContainerQueryVariables>;
export const MarketplaceEditContainerEditMarketplaceItemDocument = gql`
    mutation MarketplaceEditContainerEditMarketplaceItem($input: EditMarketplaceItemInput!) {
        editMarketplaceItem(input: $input) {
            description
            id
            images {
                id
                uuid
                url
            }
            location
            price
            title
            status
            isPaymentNeeded
        }
    }
`;
export type MarketplaceEditContainerEditMarketplaceItemMutationFn = Apollo.MutationFunction<MarketplaceEditContainerEditMarketplaceItemMutation, MarketplaceEditContainerEditMarketplaceItemMutationVariables>;

/**
 * __useMarketplaceEditContainerEditMarketplaceItemMutation__
 *
 * To run a mutation, you first call `useMarketplaceEditContainerEditMarketplaceItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarketplaceEditContainerEditMarketplaceItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [marketplaceEditContainerEditMarketplaceItemMutation, { data, loading, error }] = useMarketplaceEditContainerEditMarketplaceItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarketplaceEditContainerEditMarketplaceItemMutation(baseOptions?: Apollo.MutationHookOptions<MarketplaceEditContainerEditMarketplaceItemMutation, MarketplaceEditContainerEditMarketplaceItemMutationVariables>) {
    return Apollo.useMutation<MarketplaceEditContainerEditMarketplaceItemMutation, MarketplaceEditContainerEditMarketplaceItemMutationVariables>(MarketplaceEditContainerEditMarketplaceItemDocument, baseOptions);
}
export type MarketplaceEditContainerEditMarketplaceItemMutationHookResult = ReturnType<typeof useMarketplaceEditContainerEditMarketplaceItemMutation>;
export type MarketplaceEditContainerEditMarketplaceItemMutationResult = Apollo.MutationResult<MarketplaceEditContainerEditMarketplaceItemMutation>;
export type MarketplaceEditContainerEditMarketplaceItemMutationOptions = Apollo.BaseMutationOptions<MarketplaceEditContainerEditMarketplaceItemMutation, MarketplaceEditContainerEditMarketplaceItemMutationVariables>;
export const MarketplaceMyItemsContainerDocument = gql`
    query MarketplaceMyItemsContainer {
        me {
            myMarketplaceItems {
                category {
                    id
                    name
                }
                createdAt
                deleteAt
                description
                id
                images {
                    id
                    uuid
                    url
                }
                isRenewable
                location
                price
                status
                title
                listingDate
                isPaymentNeeded
            }
            id
        }
    }
`;

/**
 * __useMarketplaceMyItemsContainerQuery__
 *
 * To run a query within a React component, call `useMarketplaceMyItemsContainerQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketplaceMyItemsContainerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketplaceMyItemsContainerQuery({
 *   variables: {
 *   },
 * });
 */
export function useMarketplaceMyItemsContainerQuery(baseOptions?: Apollo.QueryHookOptions<MarketplaceMyItemsContainerQuery, MarketplaceMyItemsContainerQueryVariables>) {
    return Apollo.useQuery<MarketplaceMyItemsContainerQuery, MarketplaceMyItemsContainerQueryVariables>(MarketplaceMyItemsContainerDocument, baseOptions);
}
export function useMarketplaceMyItemsContainerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MarketplaceMyItemsContainerQuery, MarketplaceMyItemsContainerQueryVariables>) {
    return Apollo.useLazyQuery<MarketplaceMyItemsContainerQuery, MarketplaceMyItemsContainerQueryVariables>(MarketplaceMyItemsContainerDocument, baseOptions);
}
export type MarketplaceMyItemsContainerQueryHookResult = ReturnType<typeof useMarketplaceMyItemsContainerQuery>;
export type MarketplaceMyItemsContainerLazyQueryHookResult = ReturnType<typeof useMarketplaceMyItemsContainerLazyQuery>;
export type MarketplaceMyItemsContainerQueryResult = Apollo.QueryResult<MarketplaceMyItemsContainerQuery, MarketplaceMyItemsContainerQueryVariables>;
export const MarketplaceMyItemsContainerDeleteMarketplaceItemDocument = gql`
    mutation MarketplaceMyItemsContainerDeleteMarketplaceItem($id: ID!) {
        deleteMarketplaceItem(id: $id) {
            id
        }
    }
`;
export type MarketplaceMyItemsContainerDeleteMarketplaceItemMutationFn = Apollo.MutationFunction<MarketplaceMyItemsContainerDeleteMarketplaceItemMutation, MarketplaceMyItemsContainerDeleteMarketplaceItemMutationVariables>;

/**
 * __useMarketplaceMyItemsContainerDeleteMarketplaceItemMutation__
 *
 * To run a mutation, you first call `useMarketplaceMyItemsContainerDeleteMarketplaceItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarketplaceMyItemsContainerDeleteMarketplaceItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [marketplaceMyItemsContainerDeleteMarketplaceItemMutation, { data, loading, error }] = useMarketplaceMyItemsContainerDeleteMarketplaceItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMarketplaceMyItemsContainerDeleteMarketplaceItemMutation(baseOptions?: Apollo.MutationHookOptions<MarketplaceMyItemsContainerDeleteMarketplaceItemMutation, MarketplaceMyItemsContainerDeleteMarketplaceItemMutationVariables>) {
    return Apollo.useMutation<MarketplaceMyItemsContainerDeleteMarketplaceItemMutation, MarketplaceMyItemsContainerDeleteMarketplaceItemMutationVariables>(MarketplaceMyItemsContainerDeleteMarketplaceItemDocument, baseOptions);
}
export type MarketplaceMyItemsContainerDeleteMarketplaceItemMutationHookResult = ReturnType<typeof useMarketplaceMyItemsContainerDeleteMarketplaceItemMutation>;
export type MarketplaceMyItemsContainerDeleteMarketplaceItemMutationResult = Apollo.MutationResult<MarketplaceMyItemsContainerDeleteMarketplaceItemMutation>;
export type MarketplaceMyItemsContainerDeleteMarketplaceItemMutationOptions = Apollo.BaseMutationOptions<MarketplaceMyItemsContainerDeleteMarketplaceItemMutation, MarketplaceMyItemsContainerDeleteMarketplaceItemMutationVariables>;
export const MarketplaceMyItemsContainerRenewMarketplaceItemDocument = gql`
    mutation MarketplaceMyItemsContainerRenewMarketplaceItem($id: ID!) {
        renewMarketplaceItem(id: $id) {
            deleteAt
            id
            isRenewable
            listingDate
        }
    }
`;
export type MarketplaceMyItemsContainerRenewMarketplaceItemMutationFn = Apollo.MutationFunction<MarketplaceMyItemsContainerRenewMarketplaceItemMutation, MarketplaceMyItemsContainerRenewMarketplaceItemMutationVariables>;

/**
 * __useMarketplaceMyItemsContainerRenewMarketplaceItemMutation__
 *
 * To run a mutation, you first call `useMarketplaceMyItemsContainerRenewMarketplaceItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarketplaceMyItemsContainerRenewMarketplaceItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [marketplaceMyItemsContainerRenewMarketplaceItemMutation, { data, loading, error }] = useMarketplaceMyItemsContainerRenewMarketplaceItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMarketplaceMyItemsContainerRenewMarketplaceItemMutation(baseOptions?: Apollo.MutationHookOptions<MarketplaceMyItemsContainerRenewMarketplaceItemMutation, MarketplaceMyItemsContainerRenewMarketplaceItemMutationVariables>) {
    return Apollo.useMutation<MarketplaceMyItemsContainerRenewMarketplaceItemMutation, MarketplaceMyItemsContainerRenewMarketplaceItemMutationVariables>(MarketplaceMyItemsContainerRenewMarketplaceItemDocument, baseOptions);
}
export type MarketplaceMyItemsContainerRenewMarketplaceItemMutationHookResult = ReturnType<typeof useMarketplaceMyItemsContainerRenewMarketplaceItemMutation>;
export type MarketplaceMyItemsContainerRenewMarketplaceItemMutationResult = Apollo.MutationResult<MarketplaceMyItemsContainerRenewMarketplaceItemMutation>;
export type MarketplaceMyItemsContainerRenewMarketplaceItemMutationOptions = Apollo.BaseMutationOptions<MarketplaceMyItemsContainerRenewMarketplaceItemMutation, MarketplaceMyItemsContainerRenewMarketplaceItemMutationVariables>;
export const MarketplaceMyItemsContainerRelistMarketplaceItemDocument = gql`
    mutation MarketplaceMyItemsContainerRelistMarketplaceItem($id: ID!) {
        relistMarketplaceItem(id: $id) {
            deleteAt
            id
            isRenewable
            listingDate
        }
    }
`;

export function useMarketplaceMyItemsContainerPaidMarketplaceItemMutation(baseOptions?: Apollo.MutationHookOptions<MarketplaceMyItemsContainerPaidMarketplaceItemMutation, MarketplaceMyItemsContainerPaidMarketplaceItemMutationVariables>) {
    return Apollo.useMutation<MarketplaceMyItemsContainerRenewMarketplaceItemMutation, MarketplaceMyItemsContainerPaidMarketplaceItemMutationVariables>(MarketplaceMyItemsContainerPaidMarketplaceItemDocument, baseOptions);
}
export type MarketplaceMyItemsContainerPaidMarketplaceItemMutationHookResult = ReturnType<typeof useMarketplaceMyItemsContainerPaidMarketplaceItemMutation>;
export type MarketplaceMyItemsContainerPaidMarketplaceItemMutationResult = Apollo.MutationResult<MarketplaceMyItemsContainerPaidMarketplaceItemMutation>;
export type MarketplaceMyItemsContainerPaidMarketplaceItemMutationOptions = Apollo.BaseMutationOptions<MarketplaceMyItemsContainerPaidMarketplaceItemMutation, MarketplaceMyItemsContainerPaidMarketplaceItemMutationVariables>;
export const MarketplaceMyItemsContainerPaidMarketplaceItemDocument = gql`
    mutation MarketplaceMyItemsContainerPaidMarketplaceItem($id: ID!, $session: String!,$session_id: String!, $price: Int!) {
        paidMarketplaceItem(id: $id, session: $session, price: $price,session_id:$session_id)
    }
`;
export function useMarketplaceMyItemsContainerRelistMarketplaceItemMutation(baseOptions?: Apollo.MutationHookOptions<MarketplaceMyItemsContainerRelistMarketplaceItemMutation, MarketplaceMyItemsContainerRelistMarketplaceItemMutationVariables>) {
    return Apollo.useMutation<MarketplaceMyItemsContainerRelistMarketplaceItemMutation, MarketplaceMyItemsContainerRelistMarketplaceItemMutationVariables>(MarketplaceMyItemsContainerRelistMarketplaceItemDocument, baseOptions);
}
export type MarketplaceMyItemsContainerRelistMarketplaceItemMutationHookResult = ReturnType<typeof useMarketplaceMyItemsContainerRelistMarketplaceItemMutation>;
export type MarketplaceMyItemsContainerRelistMarketplaceItemMutationResult = Apollo.MutationResult<MarketplaceMyItemsContainerRelistMarketplaceItemMutation>;
export type MarketplaceMyItemsContainerRelistMarketplaceItemMutationOptions = Apollo.BaseMutationOptions<MarketplaceMyItemsContainerRelistMarketplaceItemMutation, MarketplaceMyItemsContainerRelistMarketplaceItemMutationVariables>;
export const MarketplaceNewContainerCreateMarketplaceItemDocument = gql`
    mutation MarketplaceNewContainerCreateMarketplaceItem($input: CreateMarketplaceItemInput!) {
        createMarketplaceItem(input: $input) {
            id
        }
    }
`;
export type MarketplaceNewContainerCreateMarketplaceItemMutationFn = Apollo.MutationFunction<MarketplaceNewContainerCreateMarketplaceItemMutation, MarketplaceNewContainerCreateMarketplaceItemMutationVariables>;

/**
 * __useMarketplaceNewContainerCreateMarketplaceItemMutation__
 *
 * To run a mutation, you first call `useMarketplaceNewContainerCreateMarketplaceItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarketplaceNewContainerCreateMarketplaceItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [marketplaceNewContainerCreateMarketplaceItemMutation, { data, loading, error }] = useMarketplaceNewContainerCreateMarketplaceItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarketplaceNewContainerCreateMarketplaceItemMutation(baseOptions?: Apollo.MutationHookOptions<MarketplaceNewContainerCreateMarketplaceItemMutation, MarketplaceNewContainerCreateMarketplaceItemMutationVariables>) {
    return Apollo.useMutation<MarketplaceNewContainerCreateMarketplaceItemMutation, MarketplaceNewContainerCreateMarketplaceItemMutationVariables>(MarketplaceNewContainerCreateMarketplaceItemDocument, baseOptions);
}
export type MarketplaceNewContainerCreateMarketplaceItemMutationHookResult = ReturnType<typeof useMarketplaceNewContainerCreateMarketplaceItemMutation>;
export type MarketplaceNewContainerCreateMarketplaceItemMutationResult = Apollo.MutationResult<MarketplaceNewContainerCreateMarketplaceItemMutation>;
export type MarketplaceNewContainerCreateMarketplaceItemMutationOptions = Apollo.BaseMutationOptions<MarketplaceNewContainerCreateMarketplaceItemMutation, MarketplaceNewContainerCreateMarketplaceItemMutationVariables>;
export const MarketplaceNewDetailsContainerDocument = gql`
    query MarketplaceNewDetailsContainer {
        marketplaceCategories {
            id
            name
        }
    }
`;

export function useMarketplaceNewContainerCheckoutMarketplaceItemMutation(baseOptions?: Apollo.MutationHookOptions<MarketplaceNewContainerCheckoutMarketplaceItemMutation, MarketplaceMyItemsContainerCheckoutMarketplaceItemMutationVariables>) {
    return Apollo.useMutation<MarketplaceNewContainerCheckoutMarketplaceItemMutation, MarketplaceMyItemsContainerCheckoutMarketplaceItemMutationVariables>(MarketplaceCheckoutDocument, baseOptions);
}
export type MarketplaceNewContainerCheckoutMarketplaceItemMutationHookResult = ReturnType<typeof useMarketplaceNewContainerCheckoutMarketplaceItemMutation>;
export type MarketplaceNewContainerCheckoutMarketplaceItemMutationResult = Apollo.MutationResult<MarketplaceNewContainerCheckoutMarketplaceItemMutation>;
export type MarketplaceNewContainerCheckoutMarketplaceItemMutationOptions = Apollo.BaseMutationOptions<MarketplaceNewContainerCheckoutMarketplaceItemMutation, MarketplaceMyItemsContainerCheckoutMarketplaceItemMutationVariables>;
export const MarketplaceCheckoutDocument = gql`
    mutation MarketplaceNewContainerCheckoutMarketplaceItemMutation($id: ID!) {
        checkoutMarketplaceItem(id: $id)
    }
`;

/**
 * __useMarketplaceNewDetailsContainerQuery__
 *
 * To run a query within a React component, call `useMarketplaceNewDetailsContainerQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketplaceNewDetailsContainerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketplaceNewDetailsContainerQuery({
 *   variables: {
 *   },
 * });
 */
export function useMarketplaceNewDetailsContainerQuery(baseOptions?: Apollo.QueryHookOptions<MarketplaceNewDetailsContainerQuery, MarketplaceNewDetailsContainerQueryVariables>) {
    return Apollo.useQuery<MarketplaceNewDetailsContainerQuery, MarketplaceNewDetailsContainerQueryVariables>(MarketplaceNewDetailsContainerDocument, baseOptions);
}
export function useMarketplaceNewDetailsContainerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MarketplaceNewDetailsContainerQuery, MarketplaceNewDetailsContainerQueryVariables>) {
    return Apollo.useLazyQuery<MarketplaceNewDetailsContainerQuery, MarketplaceNewDetailsContainerQueryVariables>(MarketplaceNewDetailsContainerDocument, baseOptions);
}
export type MarketplaceNewDetailsContainerQueryHookResult = ReturnType<typeof useMarketplaceNewDetailsContainerQuery>;
export type MarketplaceNewDetailsContainerLazyQueryHookResult = ReturnType<typeof useMarketplaceNewDetailsContainerLazyQuery>;
export type MarketplaceNewDetailsContainerQueryResult = Apollo.QueryResult<MarketplaceNewDetailsContainerQuery, MarketplaceNewDetailsContainerQueryVariables>;
export const MarketplacePendingContainerDocument = gql`
    query MarketplacePendingContainer($filter: MarketplaceItemsFilterInput) {
        marketplaceItems(filter: $filter) {
            category {
                id
                name
            }
            createdAt
            description
            id
            images {
                id
                uuid
                url
            }
            location
            price
            status
            title
            isPaymentNeeded
            paymentUrl
        }
    }
`;

/**
 * __useMarketplacePendingContainerQuery__
 *
 * To run a query within a React component, call `useMarketplacePendingContainerQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketplacePendingContainerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketplacePendingContainerQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useMarketplacePendingContainerQuery(baseOptions?: Apollo.QueryHookOptions<MarketplacePendingContainerQuery, MarketplacePendingContainerQueryVariables>) {
    return Apollo.useQuery<MarketplacePendingContainerQuery, MarketplacePendingContainerQueryVariables>(MarketplacePendingContainerDocument, baseOptions);
}
export function useMarketplacePendingContainerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MarketplacePendingContainerQuery, MarketplacePendingContainerQueryVariables>) {
    return Apollo.useLazyQuery<MarketplacePendingContainerQuery, MarketplacePendingContainerQueryVariables>(MarketplacePendingContainerDocument, baseOptions);
}
export type MarketplacePendingContainerQueryHookResult = ReturnType<typeof useMarketplacePendingContainerQuery>;
export type MarketplacePendingContainerLazyQueryHookResult = ReturnType<typeof useMarketplacePendingContainerLazyQuery>;
export type MarketplacePendingContainerQueryResult = Apollo.QueryResult<MarketplacePendingContainerQuery, MarketplacePendingContainerQueryVariables>;
export const MarketplacePendingContainerApproveMarketplaceItemDocument = gql`
    mutation MarketplacePendingContainerApproveMarketplaceItem($id: ID!) {
        approveMarketplaceItem(id: $id) {
            id
            status
        }
    }
`;
export type MarketplacePendingContainerApproveMarketplaceItemMutationFn = Apollo.MutationFunction<MarketplacePendingContainerApproveMarketplaceItemMutation, MarketplacePendingContainerApproveMarketplaceItemMutationVariables>;

/**
 * __useMarketplacePendingContainerApproveMarketplaceItemMutation__
 *
 * To run a mutation, you first call `useMarketplacePendingContainerApproveMarketplaceItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarketplacePendingContainerApproveMarketplaceItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [marketplacePendingContainerApproveMarketplaceItemMutation, { data, loading, error }] = useMarketplacePendingContainerApproveMarketplaceItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMarketplacePendingContainerApproveMarketplaceItemMutation(baseOptions?: Apollo.MutationHookOptions<MarketplacePendingContainerApproveMarketplaceItemMutation, MarketplacePendingContainerApproveMarketplaceItemMutationVariables>) {
    return Apollo.useMutation<MarketplacePendingContainerApproveMarketplaceItemMutation, MarketplacePendingContainerApproveMarketplaceItemMutationVariables>(MarketplacePendingContainerApproveMarketplaceItemDocument, baseOptions);
}
export type MarketplacePendingContainerApproveMarketplaceItemMutationHookResult = ReturnType<typeof useMarketplacePendingContainerApproveMarketplaceItemMutation>;
export type MarketplacePendingContainerApproveMarketplaceItemMutationResult = Apollo.MutationResult<MarketplacePendingContainerApproveMarketplaceItemMutation>;
export type MarketplacePendingContainerApproveMarketplaceItemMutationOptions = Apollo.BaseMutationOptions<MarketplacePendingContainerApproveMarketplaceItemMutation, MarketplacePendingContainerApproveMarketplaceItemMutationVariables>;
export const MarketplacePendingContainerRejectMarketplaceItemDocument = gql`
    mutation MarketplacePendingContainerRejectMarketplaceItem($id: ID!, $reason: String!, $feedback: String) {
        rejectMarketplaceItem(id: $id, reason: $reason, feedback: $feedback) {
            id
            status
        }
    }
`;
export type MarketplacePendingContainerRejectMarketplaceItemMutationFn = Apollo.MutationFunction<MarketplacePendingContainerRejectMarketplaceItemMutation, MarketplacePendingContainerRejectMarketplaceItemMutationVariables>;

/**
 * __useMarketplacePendingContainerRejectMarketplaceItemMutation__
 *
 * To run a mutation, you first call `useMarketplacePendingContainerRejectMarketplaceItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarketplacePendingContainerRejectMarketplaceItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [marketplacePendingContainerRejectMarketplaceItemMutation, { data, loading, error }] = useMarketplacePendingContainerRejectMarketplaceItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *      reason: // value for 'reason'
 *      feedback: // value for 'feedback'
 *   },
 * });
 */
export function useMarketplacePendingContainerRejectMarketplaceItemMutation(baseOptions?: Apollo.MutationHookOptions<MarketplacePendingContainerRejectMarketplaceItemMutation, MarketplacePendingContainerRejectMarketplaceItemMutationVariables>) {
    return Apollo.useMutation<MarketplacePendingContainerRejectMarketplaceItemMutation, MarketplacePendingContainerRejectMarketplaceItemMutationVariables>(MarketplacePendingContainerRejectMarketplaceItemDocument, baseOptions);
}
export type MarketplacePendingContainerRejectMarketplaceItemMutationHookResult = ReturnType<typeof useMarketplacePendingContainerRejectMarketplaceItemMutation>;
export type MarketplacePendingContainerRejectMarketplaceItemMutationResult = Apollo.MutationResult<MarketplacePendingContainerRejectMarketplaceItemMutation>;
export type MarketplacePendingContainerRejectMarketplaceItemMutationOptions = Apollo.BaseMutationOptions<MarketplacePendingContainerRejectMarketplaceItemMutation, MarketplacePendingContainerRejectMarketplaceItemMutationVariables>;
export const SosEditContainerDocument = gql`
    query SosEditContainer($id: ID!) {
        sosById(id: $id) {
            id
            message
            status
        }
    }
`;

/**
 * __useSosEditContainerQuery__
 *
 * To run a query within a React component, call `useSosEditContainerQuery` and pass it any options that fit your needs.
 * When your component renders, `useSosEditContainerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSosEditContainerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSosEditContainerQuery(baseOptions: Apollo.QueryHookOptions<SosEditContainerQuery, SosEditContainerQueryVariables>) {
    return Apollo.useQuery<SosEditContainerQuery, SosEditContainerQueryVariables>(SosEditContainerDocument, baseOptions);
}
export function useSosEditContainerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SosEditContainerQuery, SosEditContainerQueryVariables>) {
    return Apollo.useLazyQuery<SosEditContainerQuery, SosEditContainerQueryVariables>(SosEditContainerDocument, baseOptions);
}
export type SosEditContainerQueryHookResult = ReturnType<typeof useSosEditContainerQuery>;
export type SosEditContainerLazyQueryHookResult = ReturnType<typeof useSosEditContainerLazyQuery>;
export type SosEditContainerQueryResult = Apollo.QueryResult<SosEditContainerQuery, SosEditContainerQueryVariables>;
export const SosEditContainerEditSosItemDocument = gql`
    mutation SosEditContainerEditSosItem($input: EditSosInput!) {
        editSos(input: $input) {
            id
            message
            status
        }
    }
`;
export type SosEditContainerEditSosItemMutationFn = Apollo.MutationFunction<SosEditContainerEditSosItemMutation, SosEditContainerEditSosItemMutationVariables>;

/**
 * __useSosEditContainerEditSosItemMutation__
 *
 * To run a mutation, you first call `useSosEditContainerEditSosItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSosEditContainerEditSosItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sosEditContainerEditSosItemMutation, { data, loading, error }] = useSosEditContainerEditSosItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSosEditContainerEditSosItemMutation(baseOptions?: Apollo.MutationHookOptions<SosEditContainerEditSosItemMutation, SosEditContainerEditSosItemMutationVariables>) {
    return Apollo.useMutation<SosEditContainerEditSosItemMutation, SosEditContainerEditSosItemMutationVariables>(SosEditContainerEditSosItemDocument, baseOptions);
}
export type SosEditContainerEditSosItemMutationHookResult = ReturnType<typeof useSosEditContainerEditSosItemMutation>;
export type SosEditContainerEditSosItemMutationResult = Apollo.MutationResult<SosEditContainerEditSosItemMutation>;
export type SosEditContainerEditSosItemMutationOptions = Apollo.BaseMutationOptions<SosEditContainerEditSosItemMutation, SosEditContainerEditSosItemMutationVariables>;
export const SosMySosContainerDocument = gql`
    query SosMySosContainer {
        me {
            mySos {
                createdAt
                distance
                id
                message
                status
                isItemPaid
            }
            id
        }
    }
`;

/**
 * __useSosMySosContainerQuery__
 *
 * To run a query within a React component, call `useSosMySosContainerQuery` and pass it any options that fit your needs.
 * When your component renders, `useSosMySosContainerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSosMySosContainerQuery({
 *   variables: {
 *   },
 * });
 */
export function useSosMySosContainerQuery(baseOptions?: Apollo.QueryHookOptions<SosMySosContainerQuery, SosMySosContainerQueryVariables>) {
    return Apollo.useQuery<SosMySosContainerQuery, SosMySosContainerQueryVariables>(SosMySosContainerDocument, baseOptions);
}
export function useSosMySosContainerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SosMySosContainerQuery, SosMySosContainerQueryVariables>) {
    return Apollo.useLazyQuery<SosMySosContainerQuery, SosMySosContainerQueryVariables>(SosMySosContainerDocument, baseOptions);
}
export type SosMySosContainerQueryHookResult = ReturnType<typeof useSosMySosContainerQuery>;
export type SosMySosContainerLazyQueryHookResult = ReturnType<typeof useSosMySosContainerLazyQuery>;
export type SosMySosContainerQueryResult = Apollo.QueryResult<SosMySosContainerQuery, SosMySosContainerQueryVariables>;
export const SosMySosContainerDeleteSosDocument = gql`
    mutation SosMySosContainerDeleteSos($id: ID!) {
        deleteSos(id: $id) {
            id
        }
    }
`;
export type SosMySosContainerDeleteSosMutationFn = Apollo.MutationFunction<SosMySosContainerDeleteSosMutation, SosMySosContainerDeleteSosMutationVariables>;

/**
 * __useSosMySosContainerDeleteSosMutation__
 *
 * To run a mutation, you first call `useSosMySosContainerDeleteSosMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSosMySosContainerDeleteSosMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sosMySosContainerDeleteSosMutation, { data, loading, error }] = useSosMySosContainerDeleteSosMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSosMySosContainerDeleteSosMutation(baseOptions?: Apollo.MutationHookOptions<SosMySosContainerDeleteSosMutation, SosMySosContainerDeleteSosMutationVariables>) {
    return Apollo.useMutation<SosMySosContainerDeleteSosMutation, SosMySosContainerDeleteSosMutationVariables>(SosMySosContainerDeleteSosDocument, baseOptions);
}
export type SosMySosContainerDeleteSosMutationHookResult = ReturnType<typeof useSosMySosContainerDeleteSosMutation>;
export type SosMySosContainerDeleteSosMutationResult = Apollo.MutationResult<SosMySosContainerDeleteSosMutation>;
export type SosMySosContainerDeleteSosMutationOptions = Apollo.BaseMutationOptions<SosMySosContainerDeleteSosMutation, SosMySosContainerDeleteSosMutationVariables>;

export const SosMySosContainerPaidSosDocument = gql`
    mutation SosMySosContainerPaidSos($id: ID!, $session: String!, $session_id: String!, $price: Int!) {
  paidSos(id: $id, session: $session, price: $price, session_id: $session_id)
}
    `;
export type SosMySosContainerPaidSosMutationFn = Apollo.MutationFunction<SosMySosContainerPaidSosMutation, SosMySosContainerPaidSosMutationVariables>;

/**
 * __useSosMySosContainerPaidSosMutation__
 *
 * To run a mutation, you first call `useSosMySosContainerPaidSosMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSosMySosContainerPaidSosMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sosMySosContainerPaidSosMutation, { data, loading, error }] = useSosMySosContainerPaidSosMutation({
 *   variables: {
 *      id: // value for 'id'
 *      session: // value for 'session'
 *      session_id: // value for 'session_id'
 *      price: // value for 'price'
 *   },
 * });
 */
export function useSosMySosContainerPaidSosMutation(baseOptions?: Apollo.MutationHookOptions<SosMySosContainerPaidSosMutation, SosMySosContainerPaidSosMutationVariables>) {
        return Apollo.useMutation<SosMySosContainerPaidSosMutation, SosMySosContainerPaidSosMutationVariables>(SosMySosContainerPaidSosDocument, baseOptions);
      }
export type SosMySosContainerPaidSosMutationHookResult = ReturnType<typeof useSosMySosContainerPaidSosMutation>;
export type SosMySosContainerPaidSosMutationResult = Apollo.MutationResult<SosMySosContainerPaidSosMutation>;
export type SosMySosContainerPaidSosMutationOptions = Apollo.BaseMutationOptions<SosMySosContainerPaidSosMutation, SosMySosContainerPaidSosMutationVariables>;


export const SosNewContainerCreateSosDocument = gql`
    mutation SosNewContainerCreateSos($input: CreateSosInput!) {
        createSos(input: $input) {
            id
        }
    }
`;
export type SosNewContainerCreateSosMutationFn = Apollo.MutationFunction<SosNewContainerCreateSosMutation, SosNewContainerCreateSosMutationVariables>;

/**
 * __useSosNewContainerCreateSosMutation__
 *
 * To run a mutation, you first call `useSosNewContainerCreateSosMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSosNewContainerCreateSosMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sosNewContainerCreateSosMutation, { data, loading, error }] = useSosNewContainerCreateSosMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSosNewContainerCreateSosMutation(baseOptions?: Apollo.MutationHookOptions<SosNewContainerCreateSosMutation, SosNewContainerCreateSosMutationVariables>) {
    return Apollo.useMutation<SosNewContainerCreateSosMutation, SosNewContainerCreateSosMutationVariables>(SosNewContainerCreateSosDocument, baseOptions);
}
export type SosNewContainerCreateSosMutationHookResult = ReturnType<typeof useSosNewContainerCreateSosMutation>;
export type SosNewContainerCreateSosMutationResult = Apollo.MutationResult<SosNewContainerCreateSosMutation>;
export type SosNewContainerCreateSosMutationOptions = Apollo.BaseMutationOptions<SosNewContainerCreateSosMutation, SosNewContainerCreateSosMutationVariables>;

export const SosNewContainerCheckoutSosDocument = gql`
    mutation SosNewContainerCheckoutSos($id: ID!) {
  checkoutSos(id: $id)
}
    `;
export type SosNewContainerCheckoutSosMutationFn = Apollo.MutationFunction<SosNewContainerCheckoutSosMutation, SosNewContainerCheckoutSosMutationVariables>;

/**
 * __useSosNewContainerCheckoutSosMutation__
 *
 * To run a mutation, you first call `useSosNewContainerCheckoutSosMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSosNewContainerCheckoutSosMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sosNewContainerCheckoutSosMutation, { data, loading, error }] = useSosNewContainerCheckoutSosMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSosNewContainerCheckoutSosMutation(baseOptions?: Apollo.MutationHookOptions<SosNewContainerCheckoutSosMutation, SosNewContainerCheckoutSosMutationVariables>) {
        return Apollo.useMutation<SosNewContainerCheckoutSosMutation, SosNewContainerCheckoutSosMutationVariables>(SosNewContainerCheckoutSosDocument, baseOptions);
      }
export type SosNewContainerCheckoutSosMutationHookResult = ReturnType<typeof useSosNewContainerCheckoutSosMutation>;
export type SosNewContainerCheckoutSosMutationResult = Apollo.MutationResult<SosNewContainerCheckoutSosMutation>;
export type SosNewContainerCheckoutSosMutationOptions = Apollo.BaseMutationOptions<SosNewContainerCheckoutSosMutation, SosNewContainerCheckoutSosMutationVariables>;


export const SosPendingContainerDocument = gql`
    query SosPendingContainer {
        sos {
            createdAt
            distance
            id
            message
            status
            isDeleted
            isItemPaid
            paidPrice
            paymentBreakdown {
                rate
                total
                cardFee
                smsCost
                twillioGbp
                twilllioFee
                potntialTotal
                twilllioSellingFee
            }
            user {
                name
                email
                mobile
                mySos {
                    createdAt
                    distance
                    id
                    message
                    status
                    isDeleted
                }
            }
        }
    }
`;

export const SosNewContainerSosCostDocument = gql`
    query SosNewContainerSosCost($long: Float!, $lat: Float!, $distance: Int!) {
  sosCost(long: $long, lat: $lat, distance: $distance)
}
    `;
/**
 * __useSosNewContainerSosCostQuery__
 *
 * To run a query within a React component, call `useSosNewContainerSosCostQuery` and pass it any options that fit your needs.
 * When your component renders, `useSosNewContainerSosCostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSosNewContainerSosCostQuery({
 *   variables: {
 *      long: // value for 'long'
 *      lat: // value for 'lat'
 *      distance: // value for 'distance'
 *   },
 * });
 */
export function useSosNewContainerSosCostQuery(baseOptions?: Apollo.QueryHookOptions<SosNewContainerSosCostQuery, SosNewContainerSosCostQueryVariables>) {
    return Apollo.useQuery<SosNewContainerSosCostQuery, SosNewContainerSosCostQueryVariables>(SosNewContainerSosCostDocument, baseOptions);
}
export function useSosNewContainerSosCostLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SosNewContainerSosCostQuery, SosNewContainerSosCostQueryVariables>) {
    return Apollo.useLazyQuery<SosNewContainerSosCostQuery, SosNewContainerSosCostQueryVariables>(SosNewContainerSosCostDocument, baseOptions);
}
export type SosNewContainerSosCostQueryHookResult = ReturnType<typeof useSosNewContainerSosCostQuery>;
export type SosNewContainerSosCostLazyQueryHookResult = ReturnType<typeof useSosNewContainerSosCostLazyQuery>;
export type SosNewContainerSosCostQueryResult = Apollo.QueryResult<SosNewContainerSosCostQuery, SosNewContainerSosCostQueryVariables>;

/**
 * __useSosPendingContainerQuery__
 *
 * To run a query within a React component, call `useSosPendingContainerQuery` and pass it any options that fit your needs.
 * When your component renders, `useSosPendingContainerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSosPendingContainerQuery({
 *   variables: {
 *   },
 * });
 */
export function useSosPendingContainerQuery(baseOptions?: Apollo.QueryHookOptions<SosPendingContainerQuery, SosPendingContainerQueryVariables>) {
    return Apollo.useQuery<SosPendingContainerQuery, SosPendingContainerQueryVariables>(SosPendingContainerDocument, baseOptions);
}
export function useSosPendingContainerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SosPendingContainerQuery, SosPendingContainerQueryVariables>) {
    return Apollo.useLazyQuery<SosPendingContainerQuery, SosPendingContainerQueryVariables>(SosPendingContainerDocument, baseOptions);
}
export type SosPendingContainerQueryHookResult = ReturnType<typeof useSosPendingContainerQuery>;
export type SosPendingContainerLazyQueryHookResult = ReturnType<typeof useSosPendingContainerLazyQuery>;
export type SosPendingContainerQueryResult = Apollo.QueryResult<SosPendingContainerQuery, SosPendingContainerQueryVariables>;
export const SosPendingContainerApproveSosDocument = gql`
    mutation SosPendingContainerApproveSos($id: ID!) {
        approveSos(id: $id) {
            id
            status
        }
    }
`;
export type SosPendingContainerApproveSosMutationFn = Apollo.MutationFunction<SosPendingContainerApproveSosMutation, SosPendingContainerApproveSosMutationVariables>;

/**
 * __useSosPendingContainerApproveSosMutation__
 *
 * To run a mutation, you first call `useSosPendingContainerApproveSosMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSosPendingContainerApproveSosMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sosPendingContainerApproveSosMutation, { data, loading, error }] = useSosPendingContainerApproveSosMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSosPendingContainerApproveSosMutation(baseOptions?: Apollo.MutationHookOptions<SosPendingContainerApproveSosMutation, SosPendingContainerApproveSosMutationVariables>) {
    return Apollo.useMutation<SosPendingContainerApproveSosMutation, SosPendingContainerApproveSosMutationVariables>(SosPendingContainerApproveSosDocument, baseOptions);
}
export type SosPendingContainerApproveSosMutationHookResult = ReturnType<typeof useSosPendingContainerApproveSosMutation>;
export type SosPendingContainerApproveSosMutationResult = Apollo.MutationResult<SosPendingContainerApproveSosMutation>;
export type SosPendingContainerApproveSosMutationOptions = Apollo.BaseMutationOptions<SosPendingContainerApproveSosMutation, SosPendingContainerApproveSosMutationVariables>;
export const SosPendingContainerRejectSosDocument = gql`
    mutation SosPendingContainerRejectSos($id: ID!, $reason: String!, $feedback: String) {
        rejectSos(id: $id, reason: $reason, feedback: $feedback) {
            id
            status
        }
    }
`;
export type SosPendingContainerRejectSosMutationFn = Apollo.MutationFunction<SosPendingContainerRejectSosMutation, SosPendingContainerRejectSosMutationVariables>;

/**
 * __useSosPendingContainerRejectSosMutation__
 *
 * To run a mutation, you first call `useSosPendingContainerRejectSosMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSosPendingContainerRejectSosMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sosPendingContainerRejectSosMutation, { data, loading, error }] = useSosPendingContainerRejectSosMutation({
 *   variables: {
 *      id: // value for 'id'
 *      reason: // value for 'reason'
 *      feedback: // value for 'feedback'
 *   },
 * });
 */
export function useSosPendingContainerRejectSosMutation(baseOptions?: Apollo.MutationHookOptions<SosPendingContainerRejectSosMutation, SosPendingContainerRejectSosMutationVariables>) {
    return Apollo.useMutation<SosPendingContainerRejectSosMutation, SosPendingContainerRejectSosMutationVariables>(SosPendingContainerRejectSosDocument, baseOptions);
}
export type SosPendingContainerRejectSosMutationHookResult = ReturnType<typeof useSosPendingContainerRejectSosMutation>;
export type SosPendingContainerRejectSosMutationResult = Apollo.MutationResult<SosPendingContainerRejectSosMutation>;
export type SosPendingContainerRejectSosMutationOptions = Apollo.BaseMutationOptions<SosPendingContainerRejectSosMutation, SosPendingContainerRejectSosMutationVariables>;
export const SosPendingContainerSubmitSosSurveyDocument = gql`
    mutation SosPendingContainerSubmitSosSurvey($token: String!, $feedback: Boolean!) {
        submitSosSurvey(token: $token, feedback: $feedback)
    }
`;
export type SosPendingContainerSubmitSosSurveyMutationFn = Apollo.MutationFunction<SosPendingContainerSubmitSosSurveyMutation, SosPendingContainerSubmitSosSurveyMutationVariables>;

/**
 * __useSosPendingContainerSubmitSosSurveyMutation__
 *
 * To run a mutation, you first call `useSosPendingContainerSubmitSosSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSosPendingContainerSubmitSosSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sosPendingContainerSubmitSosSurveyMutation, { data, loading, error }] = useSosPendingContainerSubmitSosSurveyMutation({
 *   variables: {
 *      token: // value for 'token'
 *      feedback: // value for 'feedback'
 *   },
 * });
 */
export function useSosPendingContainerSubmitSosSurveyMutation(baseOptions?: Apollo.MutationHookOptions<SosPendingContainerSubmitSosSurveyMutation, SosPendingContainerSubmitSosSurveyMutationVariables>) {
    return Apollo.useMutation<SosPendingContainerSubmitSosSurveyMutation, SosPendingContainerSubmitSosSurveyMutationVariables>(SosPendingContainerSubmitSosSurveyDocument, baseOptions);
}
export type SosPendingContainerSubmitSosSurveyMutationHookResult = ReturnType<typeof useSosPendingContainerSubmitSosSurveyMutation>;
export type SosPendingContainerSubmitSosSurveyMutationResult = Apollo.MutationResult<SosPendingContainerSubmitSosSurveyMutation>;
export type SosPendingContainerSubmitSosSurveyMutationOptions = Apollo.BaseMutationOptions<SosPendingContainerSubmitSosSurveyMutation, SosPendingContainerSubmitSosSurveyMutationVariables>;
